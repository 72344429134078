<template>
  <div class="card">
    <div class="card-body p-0">
      <div class="row p-2">
        <div class="col">
          <suc-date-period-component :type="type" @[periodChangedEvent]="periodChanged" />
        </div>
        <div class="col text-right">
          <template v-if="dayEnabled">
            <span @click="setDayPeriod" :class="{ 'period-active': isDay, period: !isDay }">{{
              $t("components.fields.day")
            }}</span>
            |
          </template>

          <template v-if="weekEnabled">
            <span @click="setWeekPeriod" :class="{ 'period-active': isWeek, period: !isWeek }">{{
              $t("components.fields.week")
            }}</span>
          </template>
          <template v-if="monthEnabled">
            |
            <span @click="setMonthPeriod" :class="{ 'period-active': isMonth, period: !isMonth }">{{
              $t("components.fields.month")
            }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SucDatePeriodComponent } from "@/components/form";
import { SUC_DATE_PERIOD_CHANGED_EVENT, SUC_PERIOD_CHANGED_EVENT } from "./index";
import { DATE_PERIOD_WEEK, DATE_PERIOD_MONTH, DATE_PERIOD_DAY } from "@/components/form";

export default {
  components: {
    SucDatePeriodComponent,
  },
  props: {
    dayEnabled: {
      type: Boolean,
      default: true,
    },
    weekEnabled: {
      type: Boolean,
      default: true,
    },
    monthEnabled: {
      type: Boolean,
      default: true,
    },
    defaultType: {
      type: String,
      validator: function (val) {
        const types = [DATE_PERIOD_DAY, DATE_PERIOD_WEEK, DATE_PERIOD_MONTH];
        if (!types.includes(val)) {
          console.error("type shoud be one of", types);
          return false;
        }
        return true;
      },
      default() {
        return DATE_PERIOD_WEEK;
      },
    },
  },
  data() {
    return {
      dateStart: null,
      dateEnd: null,
      type: this.defaultType,
    };
  },
  computed: {
    periodChangedEvent() {
      return SUC_DATE_PERIOD_CHANGED_EVENT;
    },
    isWeek() {
      return this.type === DATE_PERIOD_WEEK;
    },
    isMonth() {
      return this.type === DATE_PERIOD_MONTH;
    },
    isDay() {
      return this.type === DATE_PERIOD_DAY;
    },
  },
  methods: {
    periodChanged({ start, end }) {
      this.dateStart = start;
      this.dateEnd = end;
      this.$emit(SUC_PERIOD_CHANGED_EVENT, { start, end, type: this.type });
      EventBus.fire(SUC_PERIOD_CHANGED_EVENT, { start, end, type: this.type });
    },
    setDayPeriod() {
      this.type = DATE_PERIOD_DAY;
    },
    setWeekPeriod() {
      this.type = DATE_PERIOD_WEEK;
    },
    setMonthPeriod() {
      this.type = DATE_PERIOD_MONTH;
    },
  },
};
</script>

<style lang="scss">
.period-active {
  color: $color-primary;
  font-weight: bold;
}

.period {
  color: $color-gray-2;
}
</style>
