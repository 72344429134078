<template>
  <div v-bind="$attrs" v-on="$listeners">{{ formattedValue }}</div>
</template>

<script>
export default {
  name: "FormattedMinutesComponent",

  props: {
    val: {
      required: true,
    },
    showHours: {
      type: Boolean,
      default: true,
    },
    showMinutes: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedValue() {
      let hour = parseInt(this.val / 60);
      let minutes = parseInt(this.val - hour * 60);

      var formatted = `${hour}h`;
      if (this.showMinutes) {
        formatted += String(minutes).padStart(2, "0");
      }
      return formatted;
    },
  },
};
</script>
