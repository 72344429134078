export const ABSENCE_TYPE_UNIT_DAY = 1;
export const ABSENCE_TYPE_UNIT_HOUR = 0;

export const ABSENCE_TYPE_FILTER_SICK = 0;
export const ABSENCE_TYPE_FILTER_JUSTIFIED = 1;
export const ABSENCE_TYPE_FILTER_UNJUSTIFIED = 2;
export const ABSENCE_TYPE_FILTER_REJECTED = 3;
export const ABSENCE_TYPE_FILTER_CONFIRMWAITING = 4;
export const ABSENCE_TYPE_FILTER_NEED_REUNION = 9;

export const VACATIONS_TYPE_FILTER_CONFIRMWAITING = 5;
export const VACATIONS_TYPE_FILTER_APPROVED = 6;
export const VACATIONS_TYPE_FILTER_NEED_REUNION = 7;
export const VACATIONS_TYPE_FILTER_REJECTED = 8;

// final absence
export const SOURCE_FINAL_ABSENCE_ABSENCE_REGISTRATION = 0;
export const SOURCE_FINAL_ABSENCE_POSTED_ABSENCE = 1;
export const SOURCE_FINAL_ABSENCE_PERMISSION = 2;

// status for appointments
export const STATUS_APPOINTMENT_CONFIRMWAITING = 1;
export const STATUS_APPOINTMENT_APPROVED = 2;
export const STATUS_APPOINTMENT_REJECTED = 3;
export const STATUS_APPOINTMENT_NEEDREUNION = 4;
