<template>
  <div v-bind="$attrs" v-on="$listeners">{{ formattedValue }}</div>
</template>

<script>
import Format from "./Format";

export default {
  name: "FormattedNumberComponent",

  props: {
    val: {
      required: true,
    },
    precision: {
      type: Number,
      default: 2,
    },
  },
  computed: {
    formattedValue() {
      let formatter = new Format();
      return formatter.formatNumber(this.val, this.precision);
    },
  },
};
</script>
