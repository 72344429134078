import {
  isset,
  create24DayHours,
  fillDayHours,
  parseISOElseNull,
  parseTimeElseNull,
} from "@/utils/functions";

import { EmployeeOvertime } from "./index";

import { differenceInMinutes } from "date-fns";
export default class EmployeePlan {
  parse(data) {
    this.date = parseISOElseNull(data.date);
    this.dateFired = parseISOElseNull(data.dateFired);
    this.dayApprovedHours = data.dayApprovedHours;
    this.nightApprovedHours = data.nightApprovedHours;
    this.approvedHours = data.approvedHours;
    this.dayBiometricHours = data.dayBiometricHours;
    this.dayHours = data.dayHours;
    this.nightHours = data.nightHours;
    this.planningHours = data.planningHours;
    this.dayType = data.dayType;
    this.isCalendarHoliday = data.isCalendarHoliday;
    this.calendarHolidayDescription = data.calendarHolidayDescription;
    this.fired = data.fired;
    this.key = data.key;
    this.workShiftCode = data.workShiftCode;
    this.workShiftDescription = data.workShiftDescription;
    this.isAbsence = data.isAbsence;
    this.absenceKey = data.absenceKey;
    this.sourceFinalAbsence = data.sourceFinalAbsence;
    this.firstPartStartingTime = parseTimeElseNull(data.firstPartStartingTime);
    this.firstPartEndingTime = parseTimeElseNull(data.firstPartEndingTime);
    this.secondPartEndingTime = parseTimeElseNull(data.secondPartEndingTime);
    this.secondPartStartingTime = parseTimeElseNull(data.secondPartStartingTime);
    this.overtimeDay = isset(data.overtimeDay)
      ? new EmployeeOvertime().parse(data.overtimeDay)
      : null;
    this.overtimeNight = isset(data.overtimeNight)
      ? new EmployeeOvertime().parse(data.overtimeNight)
      : null;
    this.dayHours = this.countHours;
    return this;
  }

  /**
   * Need to calculate manually because nav doesnt calculate when change planification hours manually
   */
  get countHours() {
    let minutes = 0.0;

    if (isset(this.firstPartEndingTime) && isset(this.firstPartStartingTime)) {
      minutes += differenceInMinutes(this.firstPartEndingTime, this.firstPartStartingTime);
    }

    if (isset(this.secondPartEndingTime) && isset(this.secondPartStartingTime)) {
      minutes += differenceInMinutes(this.secondPartEndingTime, this.secondPartStartingTime);
    }
    return (minutes / 60).toFixed(1);
  }

  get hours() {
    let hours = create24DayHours();

    if (isset(this.firstPartEndingTime) && isset(this.firstPartStartingTime)) {
      hours = fillDayHours({
        hours,
        startHour: this.firstPartStartingTime,
        endHour: this.firstPartEndingTime,
      });
    }

    if (isset(this.secondPartEndingTime) && isset(this.secondPartStartingTime)) {
      hours = fillDayHours({
        hours,
        startHour: this.secondPartStartingTime,
        endHour: this.secondPartEndingTime,
      });
    }

    return hours;
  }
}
