<template>
  <div class="form-group">
    <label :for="id" v-if="title.length > 0" :class="labelClass"
      >{{ title }} <span class="text-danger" v-if="required">*</span></label
    >
    <input
      :type="type"
      class="form-control"
      :id="id"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="form[name]"
    />
    <suc-field-has-error :form="form" :field="name" />
  </div>
</template>

<script>
import { Form } from "./index";
import SucFieldHasError from "@/components/form/SucFieldHasError.vue";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    form: {
      type: Form,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
  },
  components: {
    SucFieldHasError,
  },
  data() {
    return {
      value: "",
    };
  },
};
</script>

<style scoped></style>
