<template>
  <div class="form-group">
    <label class="label-description" :class="labelClasses"> {{ title }}</label>
    <div>
      <slot :value="value">
        <div class="description" :class="valueClasses">{{ value }}</div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      required: true,
    },
    labelClasses: {
      type: [Object, Array, String],
      default: () => {},
    },
    valueClasses: {
      type: [Object, Array, String],
      default: () => {},
    },
  },
};
</script>

<style></style>
