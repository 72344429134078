<template>
  <div class="form-group">
    <label class="title" v-if="title.length > 0">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <br />

    <div
      class="custom-control custom-radio custom-control-inline"
      v-for="option in options"
      :key="option.value"
    >
      <input
        type="radio"
        :id="`option_${option.value}`"
        :name="name"
        :value="option.value"
        class="custom-control-input"
        v-model="form[name]"
        @input="changedRadio"
      />
      <label class="custom-control-label" :for="`option_${option.value}`">{{ option.name }}</label>
    </div>

    <suc-field-has-error :form="form" :field="name" />
  </div>
</template>

<script>
import { Form, RadioOption, SUC_RADIO_CHANGED_EVENT } from "@/components/form";
import SucFieldHasError from "@/components/form/SucFieldHasError.vue";

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
    options: {
      type: Array,
      validator: function (columns) {
        for (let column of columns) {
          if (!(column instanceof RadioOption)) {
            console.error("options should include only RadioOption instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SucFieldHasError,
  },
  methods: {
    changedRadio(event) {
      this.$emit(SUC_RADIO_CHANGED_EVENT, event.target.value);
    },
  },
};
</script>

<style></style>
