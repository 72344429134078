<template>
  <div class="modal" :id="id" data-keyboard="false" data-backdrop="static">
    <div
      class="modal-dialog"
      :class="{
        'modal-dialog-scrollable': modalDialogScrollable,
        'modal-xl': modalExtraLarge,
        'modal-lg': modalLarge,
        'modal-dialog-centered': centered,
      }"
    >
      <div class="modal-content data-load" :class="{ 'sk-loading': showSpinner }">
        <sk-spinner v-if="showSpinner"></sk-spinner>
        <div class="modal-header" style="border: 0">
          <slot name="title">
            <h4 class="modal-title">{{ modalTitle }}</h4>
          </slot>
          <button type="button" class="close" @click="closeButtonClicked" aria-label="Close">
            <span aria-hidden="true"><i class="fas fa-times close-icon"></i></span>
          </button>
        </div>

        <div class="modal-body" style="border: 0">
          <slot name="body"></slot>
        </div>
        <div
          class="modal-footer"
          style="border: 0"
          v-if="showPrimaryFooterButton || showSecondaryFooterButton"
        >
          <a
            href="#"
            @click.prevent="secondaryButtonClicked"
            class="secondary-button mx-4"
            v-if="showSecondaryFooterButton"
            >{{ secondaryButtonTranslation }}</a
          >

          <vue-button
            :title="primaryButtonTranslation"
            @click.prevent="primaryButtonClicked"
            class="btn-primary"
            :loading="loadingPrimaryFooterButton"
          ></vue-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "./index";
import { SkSpinner } from "@/components";
import { VueButton } from "@/components/form";

export default {
  name: "VueModal",
  components: {
    SkSpinner,
    VueButton,
  },
  props: {
    modalDialogScrollable: {
      type: Boolean,
      default: false,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "",
    },
    showSecondaryFooterButton: {
      type: Boolean,
      default: true,
    },
    showPrimaryFooterButton: {
      type: Boolean,
      default: true,
    },
    loadingSecondaryFooterButton: {
      type: Boolean,
      default: false,
    },
    loadingPrimaryFooterButton: {
      type: Boolean,
      default: false,
    },
    primaryFooterButtonTranslation: {
      type: String,
      default: null,
    },
    secondaryFooterButtonTranslation: {
      type: String,
      default: null,
    },
    modalExtraLarge: {
      type: Boolean,
      default: false,
    },
    modalLarge: {
      type: Boolean,
      default: false,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // eslint-disable-next-line no-undef
      id: _.uniqueId("modal_"),
    };
  },
  watch: {
    showModal: {
      handler(val) {
        val ? this.show() : this.hide();
      },
      immediate: true,
    },
  },
  computed: {
    secondaryButtonTranslation() {
      return this.$isset(this.secondaryFooterButtonTranslation)
        ? this.secondaryFooterButtonTranslation
        : this.$t("components.modal.close");
    },
    primaryButtonTranslation() {
      return this.$isset(this.primaryFooterButtonTranslation)
        ? this.primaryFooterButtonTranslation
        : this.$t("components.modal.save");
    },
  },
  methods: {
    primaryButtonClicked() {
      this.$emit(PRIMARY_BUTTON_CLICKED_MODAL_EVENT);
    },
    secondaryButtonClicked() {
      this.$emit(SECONDARY_BUTTON_CLICKED_MODAL_EVENT);
    },
    closeButtonClicked() {
      this.$emit(CLOSE_BUTTON_CLICKED_MODAL_EVENT);
    },
    show() {
      // eslint-disable-next-line no-undef
      $(`#${this.id}`).modal("show");
    },
    hide() {
      // eslint-disable-next-line no-undef
      $(`#${this.id}`).modal("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-button {
  color: $color-secondary;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
}

.modal-body {
  border: 0;
}

.close-icon {
  color: $color-secondary;
}
</style>
