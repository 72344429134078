// user.js
export const LOGOUT = "LOGOUT";
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_APP_CONFIGURATION = "REMOVE_APP_CONFIGURATION";
export const SAVE_AUTH_DATA = "SAVE_AUTH_DATA";
export const SAVE_APP_CONFIGURATION_DATA = "SAVE_APP_CONFIGURATION_DATA";
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const CHANGE_TO_EMPLOYEE_ROLE = "CHANGE_EMPLOYEE_ROLE";
export const CHANGE_TO_RESPONSIBLE_ROLE = "CHANGE_RESPONSIBLE_ROLE";

//lang.js
export const SET_LOCALE = "SET_LOCALE";

// planning.js
export const SET_WIDGET_PLANNING_DATA = "SET_WIDGET_pLANNING_DATA";
export const SET_WIDGET_PLANNING_DATA_UPDATED_AT = "SET_WIDGET_PLANNING_DATA_UPDATED_AT";
export const SET_WIDGET_PLANNING_DATA_INITIALIZING = "SET_WIDGET_PLANNING_DATA_INITIALIZING";
