import { EmployeePlan, Employee } from "./index";

export default class EmployeePlanData {
  parse(data) {
    this.employee = new Employee().parse(data.employee);
    this.data = data.data.map((x) => new EmployeePlan().parse(x));

    return this;
  }
}
