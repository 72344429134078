import { parseISOElseNull } from "@/utils/functions";

export default class BiometricHour {
  parse(data) {
    this.key = data.key;
    this.code = parseInt(data.code);
    this.biometricDateWithTime = parseISOElseNull(data.biometricDateWithTime);
    this.biometricType = parseInt(data.biometricType);
    this.employeeCode = data.employeeCode;
    this.createdByEmployeeCode = data.createdByEmployeeCode;
    this.establishmentName = data.establishmentName;
    this.companyNameDisplayName = data.companyNameDisplayName;

    return this;
  }

  get isEnter() {
    return this.biometricType == 0;
  }

  get isExit() {
    return this.biometricType == 1;
  }
}
