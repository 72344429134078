// state
export const state = {
  packageVersion: process.env.VUE_APP_VERSION,
  package: process.env,
  hostname: location.hostname,
  staticHontentUrl: process.env.VUE_APP_STATIC_CONTENT_URL,
};

export const getters = {
  appVersion: (state) => {
    return state.packageVersion;
  },
  copyright: (state) => {
    const year = new Date().getFullYear();
    return `Copyright © ${year}, v${state.packageVersion}`;
  },
};
