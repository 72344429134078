<template>
  <div class="container">
    <nav class="navbar pb-0 pt-0">
      <div>
        <img :src="logoPath" alt="logo" style="height: 50px" />
      </div>
      <ul class="nav ml-auto main-top-menu">
        <li>
          <profile-user-menu></profile-user-menu>
        </li>
        <!--        <li class="nav-item">-->
        <!--          <span class="nav-link info">Tienda Central</span>-->
        <!--        </li>-->
        <li class="nav-item" @click="logout">
          <a class="nav-link" href="#">
            <i class="fas fa-sign-out-alt"></i
            ><span class="ml-1">{{ $t("pages.navigation.sign_out") }}</span></a
          >
        </li>
      </ul>
    </nav>
    <nav class="navbar pb-0 pt-0">
      <ul class="nav">
        <li class="nav-item main-bottom-menu-item">
          <router-link :to="{ name: 'dashboard' }" class="nav-link" exact>
            {{ $t("pages.dashboard.title") }}
          </router-link>
        </li>
        <template v-if="isCurrentRoleEmployee">
          <li class="nav-item main-bottom-menu-item" v-if="activeEmployeePayrolls">
            <router-link :to="{ name: 'payroll' }" class="nav-link">
              {{ $t("pages.payroll.title") }}
            </router-link>
          </li>
          <li class="nav-item main-bottom-menu-item" v-if="activeEmployeePermissions">
            <router-link :to="{ name: 'permission' }" class="nav-link">
              {{ $t("pages.permission.title") }}
            </router-link>
          </li>
          <li class="nav-item main-bottom-menu-item" v-if="activeEmployeePlanning">
            <router-link :to="{ name: 'planning' }" class="nav-link">
              {{ $t("pages.planning.title") }}
            </router-link>
          </li>
        </template>
        <template v-else>
          <li class="nav-item main-bottom-menu-item">
            <router-link :to="{ name: 'payroll-employees' }" class="nav-link">
              {{ $t("pages.payroll-employees.title") }}
            </router-link>
          </li>
          <li class="nav-item main-bottom-menu-item">
            <router-link :to="{ name: 'planing-team' }" class="nav-link">
              {{ $t("pages.planing-team.title") }}
            </router-link>
          </li>
        </template>
      </ul>

      <ul class="nav">
        <li class="nav-item main-bottom-menu-item">
          <notification-component />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProfileUserMenu from "@/pages/components/ProfileUserMenu.vue";
import { NotificationComponent } from "./notification";

export default {
  name: "HeaderNavigationComponent",
  components: {
    ProfileUserMenu,
    NotificationComponent,
  },
  mounted() {
    $('[data-toggle="popover"]').popover();
  },
  computed: {
    ...mapGetters("auth", [
      "isCurrentRoleEmployee",
      "activeEmployeePayrolls",
      "activeEmployeePermissions",
      "activeEmployeePlanning",
    ]),
    ...mapGetters("auth", ["logoPath"]),
  },
  methods: {
    ...mapActions("auth", ["removeUser"]),
    logout() {
      this.removeUser();
      window.location.reload();
      // this.$router.push({ name: "login" });
    },
  },
};
</script>
