<template>
  <a href="#" class="dropdown-item notification-item" @click="notificationClicked">
    <!-- Message Start -->
    <div class="media h-25 table-responsive">
      <div class="media-body p-1">
        <h6 class="dropdown-item-title"></h6>
        <div class="text-sm">
          <span
            class="badge"
            :class="{
              'badge-danger': isStatusRejected,
              'badge-success': isStatusApproved,
              'badge-warning': isStatusNeedReuniton,
            }"
            >{{ statusDescription }}</span
          >
        </div>
        <div class="text-sm p-1">
          <i class="fas fa-exclamation-circle"></i>
          <span class="text-bold pl-2">{{ causeOfAbsenceDescription }}</span>
        </div>
        <div class="text-sm p-1">
          <i class="far fa-clock notification-icon"></i>
          <span class="pl-2">
            <formatted-date-component :val="dateStart" :withTime="true" />
            -
            <formatted-date-component :val="dateEnd" :withTime="true" />
          </span>
        </div>
        <div class="text-sm p-1" v-if="comments">
          <i class="far fa-comment"></i>
          <span class="pl-2">
            {{ comments }}
          </span>
        </div>
      </div>
    </div>
    <!-- Message End -->
  </a>
</template>

<script>
import { FormattedDateComponent } from "@/components/formatted";

import { FinalAbsence } from "@/pages/components/data";

import api from "@/api/appointment";
import { mapActions } from "vuex";

export default {
  components: {
    FormattedDateComponent,
  },
  props: {
    notificationData: {
      type: FinalAbsence,
      required: true,
    },
  },
  computed: {
    statusDescription() {
      return this.notificationData.statusDescription;
    },

    isStatusApproved() {
      return this.notificationData.isStatusApproved();
    },

    isStatusRejected() {
      return this.notificationData.isStatusRejected();
    },
    isStatusNeedReuniton() {
      return this.notificationData.isStatusNeedReuniton();
    },
    employeeName() {
      return this.notificationData.employeeName;
    },
    dateStart() {
      return this.notificationData.dateStart;
    },
    dateEnd() {
      return this.notificationData.dateEnd;
    },
    causeOfAbsenceDescription() {
      return this.notificationData.causeOfAbsenceDescription;
    },
    comments() {
      return this.notificationData.comments;
    },
  },
  methods: {
    ...mapActions("notification", ["loadNotifications"]),
    async notificationClicked() {
      await api.updateAppointmentRead({
        id: this.notificationData.id,
        employeeRead: true,
      });
      this.loadNotifications();
    },
  },
};
</script>

<style></style>
