<template>
  <div class="day-legend" v-bind="$attrs" v-on="$listeners">
    <img src="@/assets/IC_Ausencia_Pendiente.svg" /><span v-if="withTitle">
      {{ title.length > 0 ? title : $t("pages.permission.absences.confirm_waiting") }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    withTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
