import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import router from "@/router";
import { API_DOMAIN } from "./config";

if (process.env.VUE_APP_DSN_SENTRY) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN_SENTRY,
    release: process.env.VUE_APP_PROJECT_SENTRY + "@" + process.env.VUE_APP_VERSION,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", API_DOMAIN, /^\//],
      }),
    ],
    debug: process.env.NODE_ENV !== "production",
    tracesSampleRate: 1.0,
  });
}
