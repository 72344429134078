import Vue from "vue";

export default class EventBus {
  constructor() {
    this.vue = new Vue();
  }

  fire(event, data = null) {
    this.vue.$emit(event, data);
  }

  listen(events, callback) {
    this.vue.$on(events, callback);
  }

  off(events, callback) {
    this.vue.$off(events, callback);
  }
}
