export default class AbsenceType {
  parse(data) {
    this.code = data.code;
    this.description = data.description;
    this.descriptionCat = data.descriptionCat;
    this.forceSalaryLoss = data.forceSalaryLoss;
    this.holidays = data.holidays;
    this.justified = data.justified;
    this.key = data.key;
    this.medicalLeave = data.medicalLeave;
    this.paidLeave = data.paidLeave;
    this.totalAbsenceBase = data.totalAbsenceBase;
    //  0 - "HORA", 1 - "DÍA"
    this.unitOfMeasureCode = data.unitOfMeasureCode;
    this.unitOfMeasureCodeDescription = data.unitOfMeasureCodeDescription;
    this.unpaidLeave = data.unpaidLeave;
    this.commentRequired = data.commentRequired;
    this.needAproove = data.needAproove;
    this.fileRequired = data.fileRequired;

    return this;
  }
}
