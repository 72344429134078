<template>
  <div class="form-group">
    <label :for="id">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <textarea
      :id="id"
      class="form-control"
      :rows="rows"
      v-model="form[name]"
      :readonly="readonly"
      v-bind="$attrs"
      v-on="$listeners"
    ></textarea>
    <suc-field-has-error :form="form" :field="name" />
  </div>
</template>

<script>
import { Form } from "./index";
import SucFieldHasError from "@/components/form/SucFieldHasError.vue";

export default {
  props: {
    rows: {
      type: [Number, String],
      default: "3",
    },
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SucFieldHasError,
  },
};
</script>

<style></style>
