export default class Employee {
  parse(data) {
    this.firstFamilyName = data.firstFamilyName;
    this.fullName = data.fullName;
    this.name = data.name;
    this.employeeCode = data.employeeCode;
    this.roleColor = data.roleColor;
    this.roleOrder = data.roleOrder;
    this.hourBalance = data.hourBalance.toFixed(1);
    this.holidaysRemaining = data.holidaysRemaining.toFixed(1);

    return this;
  }
}
