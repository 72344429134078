import { deepCopy, isset } from "@/utils/functions";
import Errors from "./Errors";

class Form {
  /**
   * Create a new form instance.
   *
   * @param {Object} data
   * @param {options} options key->value object, where key is the name of the field from date and value is a field from selected object
   * @param {Array[Rule]} fieldsRules
   * @param {Array[FormRule]} formRules
   */
  constructor({ data = {}, options = {}, fieldsRules = [], formRules = [] }) {
    this.originalData = deepCopy(data);
    this.errors = new Errors();
    this.options = options;
    this.fieldsRules = fieldsRules;
    this.formRules = formRules;
    Object.assign(this, data);
  }

  /**
   * Fill form data.
   *
   * @param {Object} data
   */
  fill(data) {
    this.keys().forEach((key) => {
      this[key] = data[key];
    });
  }

  /**
   * Get the form data keys.
   *
   * @return {Array}
   */
  keys() {
    return Object.keys(this).filter((key) => !Form.ignore.includes(key));
  }

  /**
   * Get the form data.
   *
   * @return {Object}
   */
  data() {
    return this.keys().reduce((data, key) => {
      /** for this[key].key for v-select element */
      let value = this[key];
      if (isset(value)) {
        if (Array.isArray(value) && isset(value[0]) && !(value[0] instanceof Date)) {
          let values = [];
          for (let el of value) {
            if (el instanceof Object && isset(el.key)) values.push(el.key);
            else values.push(el);
          }
          value = values;
        } else if (Array.isArray(value) && value[0] instanceof Date && value[1] instanceof Date) {
          //for date range
          value[0] = this.formatDate(value[0]);
          value[1] = this.formatDate(value[1]);
        } else if (isset(this.options[key])) {
          //for selectbox when selected value is object
          value = this[key][this.options[key]];
        } else if (isset(value.key)) {
          value = this[key].key;
        } else if (value instanceof Date) {
          value = this.formatDate(value);
        }
        return { ...data, [key]: value };
      } else {
        return data;
      }
    }, {});
  }

  formatDate(value) {
    return (
      value.getFullYear() +
      "-" +
      this.appendLeadingZeroes(value.getMonth() + 1) +
      "-" +
      this.appendLeadingZeroes(value.getDate()) +
      "T" +
      this.appendLeadingZeroes(value.getHours()) +
      ":" +
      this.appendLeadingZeroes(value.getMinutes()) +
      ":" +
      this.appendLeadingZeroes(value.getSeconds())
    );
  }

  appendLeadingZeroes(n) {
    if (n <= 9) {
      return "0" + n;
    }
    return n;
  }

  setFieldsRules(rules) {
    this.fieldsRules = rules;
  }

  setFormRules(rules) {
    this.formRules = rules;
  }

  validate() {
    var ext = this;
    ext.errors.clear();

    //fields validation rules
    this.fieldsRules
      .filter((x) => x.enabled)
      .forEach((rule) => {
        const value = ext[rule.name];
        if (!rule.validate(value)) {
          ext.errors.set(rule.name, rule.errorMessage);
        }
      });

    if (!this.errors.any()) {
      //global validation rules
      this.formRules
        .filter((x) => x.enabled)
        .forEach((rule) => {
          if (!rule.validate(ext)) {
            ext.errors.set(rule.name, rule.errorMessage);
          }
        });
    }

    return !this.errors.any();
  }

  /**
   *
   * @param {Rule} rule
   */
  addFieldRule(rule) {
    this.fieldsRules.push(rule);
  }

  /**
   *
   * @param {FormRule} rule
   */
  addFormRule(rule) {
    this.formRules.push(rule);
  }

  /**
   * Clear the form errors.
   */
  clear() {
    this.errors.clear();
    this.successful = false;
  }

  /**
   * Reset the form fields.
   */
  reset() {
    Object.keys(this)
      .filter((key) => !Form.ignore.includes(key))
      .forEach((key) => {
        this[key] = deepCopy(this.originalData[key]);
      });
  }

  /**
   * Clear errors on keydown.
   *
   * @param {KeyboardEvent} event
   */
  onKeydown(event) {
    if (event.target.name) {
      this.errors.clear(event.target.name);
    }
  }

  /**
   * Get the form raw data.
   *
   * @return {Object}
   */
  rawData() {
    return this.keys().reduce((data, key) => {
      return { ...data, [key]: this[key] };
    }, {});
  }
}

Form.routes = {};
Form.errorMessage = "Something went wrong. Please try again.";
Form.ignore = [
  "busy",
  "successful",
  "errors",
  "fieldsRules",
  "formRules",
  "originalData",
  "options",
];

export default Form;
