<template>
  <div>
    <div class="main-nav-menu mx-md-5 mb-4">
      <div class="card-body p-0">
        <header-navigation-component></header-navigation-component>
      </div>
    </div>

    <child></child>
    <div class="mx-md-5 mt-5">
      <footer-component />
    </div>
  </div>
</template>

<script>
import Child from "../Child";
import HeaderNavigationComponent from "@/pages/components/HeaderNavigationComponent.vue";
import FooterComponent from "@/pages/components/FooterComponent.vue";
import * as Sentry from "@sentry/vue";
import { mapGetters, mapState } from "vuex";

export default {
  components: {
    Child,
    HeaderNavigationComponent,
    FooterComponent,
  },
  computed: {
    ...mapGetters("auth", ["employeeCode", "isCurrentRoleEmployee", "isCurrentRoleResponsible"]),
    ...mapState("auth", ["auth"]),
  },
  created() {
    Sentry.setContext("auth", {
      ...this.auth,
      isCurrentRoleEmployee: this.isCurrentRoleEmployee,
      isCurrentRoleResponsible: this.isCurrentRoleResponsible,
    });
  },
};
</script>

<style lang="scss">
.color-primary {
  color: $color-primary !important;
}

.color-secondary {
  color: $color-secondary !important;
}

body {
  background-color: #f7f9fe;
}

.main-nav-menu {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  border-radius: 0 0.25rem;
  box-shadow: 0 4px 4px 0 #53627c33;
}

.main-top-menu {
  & a {
    color: #a712b5;
  }

  & .info {
    color: #333333;
  }
}

.main-bottom-menu-item {
  & > a {
    color: #3a009f;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
  }

  & > a:hover {
    color: #333333;
    border-bottom: #0b2e13;
  }

  & > .active {
    border-bottom: 3px solid #3a009f !important;
  }
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
}

.box-shadow {
  box-shadow: 0 4px 4px 0 rgba(83, 98, 124, 0.2);
}

label.title {
  color: $color-gray;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  text-transform: uppercase !important;
}

.custom-card {
  height: 133px;
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: 4px solid $color-primary;
  width: 200px;

  & > .card-body .card-title {
    color: $color-gray;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22.4px;
  }

  & .card-text {
    color: $color-primary;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
  }
}

.plan {
  .plan-bar {
    border-radius: $border-radius;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(58, 0, 159, 0.1);
  }

  .plan-card {
    height: 318px;
    width: 150px;
    border-radius: $border-radius;

    & > .card-body {
      padding: 0 !important;
      margin: 0 !important;
    }
  }

  .day {
    color: $color-white;
    background-color: $color-primary;
    border-radius: $border-radius 0;

    .number {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 29px;
      text-align: center;
      padding: 10px;
    }
  }

  .plan-text-header {
    color: $color-gray;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0;
  }

  .plan-text-body {
    color: $color-black;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0;
  }

  .plan-day-body {
    margin: 0.5rem !important;
  }

  .plan-day-header {
    & > img {
      margin-top: 0.5rem !important;
      margin-right: 0.5rem !important;
    }
  }
}

/**
Permissions
 */

.permission-form {
  & label.title {
    color: $color-gray;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19.6px;
    text-transform: uppercase !important;
  }
}

.permission-calendar {
  & .month-title {
    color: #333333;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
  }

  & .day {
    max-width: 76px;
    border: 1px solid $color-gray-2;
    border-radius: $border-radius;
    box-shadow: 0 2px 2px 0 rgba(83, 98, 124, 0.2);

    & > .day-header,
    .active {
      background-color: $color-gray-3;
      border-radius: $border-radius $border-radius 0 0;
    }

    & > .active {
      background-color: $color-primary;
      border: 1px solid $color-primary;

      & > span {
        color: $color-white;
      }
    }

    & .day-body > img {
      margin: 5px;
    }
  }

  & .day-number {
    color: $color-gray;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 29px;
    text-align: center;
    padding: 0;
    margin: 0;
  }

  & .day-legend > span {
    margin-left: 4px;
  }

  .table-without-border td,
  .table-without-border > thead th {
    border: 0;
  }

  .table-without-border > thead td {
    padding: 0;
    color: $color-gray;
  }
}

/** style fo all form labels */
form label,
.label-description {
  color: $color-gray;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19.6px;
  text-transform: uppercase;
}

form .description {
  color: $color-black;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22.4px;
  height: 22.4px;
}

form .label-description {
  margin: 0;
}

.secondary-button {
  color: $color-secondary;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
}
</style>
