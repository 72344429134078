<template>
  <a href="https://apps.apple.com/us/app/empleat-app/id1590351073"
    ><img alt="Get it on Google Play" :height="height" src="@/assets/apple-store-badge.svg" />
  </a>
</template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: 60,
    },
  },
};
</script>
