import { BiometricHour } from "./index";
import { parseISOElseNull } from "@/utils/functions";

export default class BiometricHourDayFiltered {
  parse(data) {
    this.day = parseISOElseNull(data.day);
    this.isValid = data.isValid;
    this.totalMinutes = data.totalMinutes;
    this.hours = data.hours.map((x) => new BiometricHour().parse(x));

    return this;
  }
}
