<template>
  <a href="https://play.google.com/store/apps/details?id=com.sucasuc.empleatapp"
    ><img alt="Get it on Google Play" :height="height" src="@/assets/google-play-badge.svg" />
  </a>
</template>
<script>
export default {
  props: {
    height: {
      type: Number,
      default: 60,
    },
  },
};
</script>
