<template>
  <div v-if="form.errors.has(field)" class="help-block error" v-html="form.errors.get(field)" />
</template>

<script>
export default {
  name: "SucFieldHasError",
  props: {
    form: {
      type: Object,
      required: true,
    },

    field: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
