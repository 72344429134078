<template>
  <div class="alert alert-danger" role="alert" v-if="form.errors.has(field)">
    <div v-html="form.errors.get(field)" />
  </div>
</template>

<script>
import { FORM_ERRORS } from "./index";

export default {
  name: "SucFormHasError",
  props: {
    form: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      default: () => {
        return FORM_ERRORS;
      },
    },
  },
};
</script>

<style>
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
