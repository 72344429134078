<template>
  <a href="#" class="dropdown-item notification-item">
    <!-- Message Start -->
    <div class="media h-25 table-responsive">
      <div class="media-body p-1">
        <h6 class="dropdown-item-title"></h6>
        <div class="text-sm">
          <absence-confirm-waiting-day-legend
            v-if="isAbsenceConfirmWaiting"
            :title="notificationData.causeOfAbsenceDescription"
          />

          <vacation-confirm-waiting-day-legend
            v-if="isVacationsConfirmWaiting"
            :title="notificationData.causeOfAbsenceDescription"
          />
        </div>
        <div class="text-sm p-1">
          <i class="fas fa-user notification-icon"></i>
          <span class="text-bold pl-2">{{ employeeName }}</span>
        </div>
        <div class="text-sm p-1">
          <i class="far fa-clock notification-icon"></i>
          <span class="pl-2">
            <formatted-date-component :val="dateStart" :withTime="true" />
            -
            <formatted-date-component :val="dateEnd" :withTime="true" />
          </span>
        </div>
      </div>
    </div>
    <!-- Message End -->
  </a>
</template>

<script>
import AbsenceConfirmWaitingDayLegend from "@/pages/components/day-legend/AbsenceConfirmWaitingDayLegend.vue";
import VacationConfirmWaitingDayLegend from "@/pages/components/day-legend/VacationConfirmWaitingDayLegend.vue";
import { FormattedDateComponent } from "@/components/formatted";

import { FinalAbsence } from "@/pages/components/data";

export default {
  components: {
    AbsenceConfirmWaitingDayLegend,
    VacationConfirmWaitingDayLegend,
    FormattedDateComponent,
  },
  props: {
    notificationData: {
      type: FinalAbsence,
      required: true,
    },
  },
  computed: {
    isAbsenceConfirmWaiting() {
      return this.notificationData.isAbsenceConfirmWaiting();
    },
    isVacationsConfirmWaiting() {
      return this.notificationData.isVacationsConfirmWaiting();
    },
    employeeName() {
      return this.notificationData.employeeName;
    },
    dateStart() {
      return this.notificationData.dateStart;
    },
    dateEnd() {
      return this.notificationData.dateEnd;
    },
  },
};
</script>

<style></style>
