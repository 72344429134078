import HeaderNavigationComponent from "@/pages/components/HeaderNavigationComponent.vue";
import CardInfoComponent from "@/pages/components/CardInfoComponent.vue";
import CardDataComponent from "@/pages/components/card-data/CardDataComponent.vue";
import SuccessInfoModalComponent from "@/pages/components/SuccessInfoModalComponent.vue";
import ShowConfirmModalComponent from "@/pages/components/ShowConfirmModalComponent.vue";

import {
  SHOW_CONFIRM_INFO_MODAL,
  SHOW_ERROR_INFO_MODAL,
  SHOW_SUCCESS_INFO_MODAL,
} from "@/pages/components/events";

import {
  ABSENCE_TYPE_FILTER_SICK,
  ABSENCE_TYPE_FILTER_JUSTIFIED,
  ABSENCE_TYPE_FILTER_UNJUSTIFIED,
  ABSENCE_TYPE_FILTER_REJECTED,
  ABSENCE_TYPE_FILTER_CONFIRMWAITING,
  ABSENCE_TYPE_FILTER_NEED_REUNION,
  VACATIONS_TYPE_FILTER_CONFIRMWAITING,
  VACATIONS_TYPE_FILTER_APPROVED,
  VACATIONS_TYPE_FILTER_NEED_REUNION,
  VACATIONS_TYPE_FILTER_REJECTED,
  SOURCE_FINAL_ABSENCE_ABSENCE_REGISTRATION,
  SOURCE_FINAL_ABSENCE_POSTED_ABSENCE,
  SOURCE_FINAL_ABSENCE_PERMISSION,
  STATUS_APPOINTMENT_CONFIRMWAITING,
  STATUS_APPOINTMENT_APPROVED,
  STATUS_APPOINTMENT_REJECTED,
  STATUS_APPOINTMENT_NEEDREUNION,
} from "@/pages/components/constants";

export {
  SHOW_CONFIRM_INFO_MODAL,
  SHOW_ERROR_INFO_MODAL,
  SHOW_SUCCESS_INFO_MODAL,
  ABSENCE_TYPE_FILTER_SICK,
  ABSENCE_TYPE_FILTER_JUSTIFIED,
  ABSENCE_TYPE_FILTER_UNJUSTIFIED,
  ABSENCE_TYPE_FILTER_REJECTED,
  ABSENCE_TYPE_FILTER_NEED_REUNION,
  ABSENCE_TYPE_FILTER_CONFIRMWAITING,
  VACATIONS_TYPE_FILTER_CONFIRMWAITING,
  VACATIONS_TYPE_FILTER_APPROVED,
  VACATIONS_TYPE_FILTER_NEED_REUNION,
  VACATIONS_TYPE_FILTER_REJECTED,
  SOURCE_FINAL_ABSENCE_ABSENCE_REGISTRATION,
  SOURCE_FINAL_ABSENCE_POSTED_ABSENCE,
  SOURCE_FINAL_ABSENCE_PERMISSION,
  STATUS_APPOINTMENT_CONFIRMWAITING,
  STATUS_APPOINTMENT_APPROVED,
  STATUS_APPOINTMENT_REJECTED,
  STATUS_APPOINTMENT_NEEDREUNION,
  HeaderNavigationComponent,
  CardInfoComponent,
  CardDataComponent,
  SuccessInfoModalComponent,
  ShowConfirmModalComponent,
};
