<template>
  <div>
    <a class="nav-link" data-toggle="dropdown" href="#">
      <i class="far fa-bell fa-lg"></i>
      <span class="badge badge-danger notification-count" v-if="notificationCount > 0">{{
        notificationCount
      }}</span>
    </a>
    <div class="dropdown-menu dropdown-menu-right notification-menu p-0 mt-2">
      <div v-if="isCurrentRoleResponsible">
        <template v-for="(notification, index) in appointmentNotifications">
          <appointment-responsible-notification
            :notificationData="notification.data"
            :key="`appointment_notify_${index}`"
          />
          <div class="dropdown-divider m-0" :key="`appointment_notify_divider_${index}`"></div>
        </template>
      </div>

      <div v-else>
        <template v-for="(notification, index) in appointmentNotifications">
          <appointment-employee-notification
            :notificationData="notification.data"
            :key="`appointment_notify_${index}`"
          />
          <div class="dropdown-divider m-0" :key="`appointment_notify_divider_${index}`"></div>
        </template>
      </div>
      <!-- <a href="#" class="dropdown-item dropdown-footer pt-2 pb-2">See All Messages</a> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { NOTIFICATION_APPOINTMENT_TYPE } from "../data";
import { AppointmentResponsibleNotification, AppointmentEmployeeNotification } from "./types";

export default {
  components: {
    AppointmentResponsibleNotification,
    AppointmentEmployeeNotification,
  },
  computed: {
    ...mapGetters("auth", ["isCurrentRoleResponsible", "isCurrentRoleEmployee"]),
    ...mapState("notification", ["notifications"]),
    notificationCount() {
      return this.notifications.length;
    },
    appointmentNotifications() {
      return this.notifications.filter((x) => x.type == NOTIFICATION_APPOINTMENT_TYPE);
    },
  },
};
</script>

<style>
.notification-menu {
  border: 0px !important;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(58, 0, 159, 0.2);
}

.notification-item {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.notification-icon {
  color: #9b9393;
}

.notification-count {
  position: relative;
  top: 5px;
  left: -4px;
  text-align: center;
}
</style>
