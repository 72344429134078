<template>
  <div>
    <popper v-if="isResponsible" ref="popper" trigger="clickToToggle">
      <div class="profile-menu profile-user-nav">
        <article>
          <h4 class="text-left">{{ $t("pages.users.my_profile") }}</h4>
          <div class="row">
            <div class="col text-center">
              <div
                class="user-type-icon user-type-icon-left"
                @click="setEmployeeRole"
                :class="{ 'user-type-no-active': isCurrentRoleResponsible }"
              >
                <img src="@/assets/IC_Empleado.png" alt="image" class="user-icon" />
              </div>
              <p class="user-type">{{ $t("pages.users.employee") }}</p>
            </div>
            <div class="col text-center">
              <div
                class="user-type-icon user-type-icon-right"
                @click="setResponsibleRole"
                :class="{ 'user-type-no-active': isCurrentRoleEmployee }"
              >
                <img src="@/assets/IC_Jefe.png" alt="image" class="user-icon" />
              </div>
              <p class="user-type">{{ $t("pages.users.boss") }}</p>
            </div>
          </div>
          <div class="row py-3">
            <div class="col text-right">
              <a class="profile-link" href="#" @click="toProfilePage">
                {{ $t("pages.users.see_my_data") }}
                <img src="@/assets/IC_Arrow_Right_pink_secondary.svg" alt="more" />
              </a>
            </div>
          </div>
        </article>
      </div>

      <div slot="reference">
        <div class="text-right name-user">
          <a href="#"
            ><span>{{ userName }} - </span>
            <span v-if="isCurrentRoleEmployee">{{ $t("pages.users.employee") }}</span>
            <span v-else>{{ $t("pages.users.boss") }}</span>
          </a>
        </div>
      </div>
    </popper>

    <div class="name-user" v-else>
      <router-link :to="{ name: 'profile' }">
        <span>{{ userName }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

export default {
  name: "ProfileUserMenu",
  components: {
    Popper,
  },
  computed: {
    ...mapGetters("auth", [
      "userName",
      "isResponsible",
      "isCurrentRoleResponsible",
      "isCurrentRoleEmployee",
      "showEmployeePersonalData",
    ]),
  },
  methods: {
    ...mapActions("auth", ["changeToResponsibleRole", "changeToEmployee"]),
    toProfilePage(event) {
      if (this.isCurrentRoleEmployee && !this.showEmployeePersonalData) return;
      event.preventDefault();
      this.$refs.popper.doClose();
      this.$router.push({ name: "profile" });
    },
    setResponsibleRole() {
      this.changeToResponsibleRole();
      this.toHome();
    },
    setEmployeeRole() {
      this.changeToEmployee();
      this.toHome();
    },
    toHome() {
      const route = this.$router.currentRoute;
      if (route.name != "dashboard") {
        this.$router.push({ name: "dashboard" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-menu {
  z-index: 200000;
  margin-top: 16px;
}

.name-user {
  padding-top: 8px;

  & span {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
  }
}

.profile-user-nav {
  width: 350px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(58, 0, 159, 0.2);

  & article > h4 {
    padding: 16px;
  }

  & .user-icon {
    width: 130px;
    height: 130px;
  }

  & .user-type {
    color: $color-gray;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    padding-top: 16px;
  }

  .user-type-icon {
    border-radius: $border-radius;
    box-shadow: -1px -1px 2px 2px rgba(83, 98, 124, 0.2);
  }

  .user-type-no-active {
    opacity: 0.18;
    border-radius: 10px;
    background-color: $color-primary;
    box-shadow: 0 0 4px 0 rgba(83, 98, 124, 0.2);
  }

  $margin-user-type: 16px;

  .user-type-icon-left {
    margin-left: $margin-user-type;
  }

  .user-type-icon-right {
    margin-right: $margin-user-type;
  }

  .profile-link {
    color: $color-secondary;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22.4px;
    margin: $margin-user-type;
  }
}
</style>
