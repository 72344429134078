import repository from "../repository/local-repository";
import * as types from "../mutation-types";

const locales = ["ca_ES", "es_ES"];

let currentLocale = "ca_ES";

const dayLong = {
  ca_ES: ["Dilluns", "Dimarts", "Dimecres", "Dijous", "Divendres", "Dissabte", "Diumenge"],
  es_ES: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
};

const dayShort = {
  ca_ES: ["Dl", "Dt", "Dc", "Dj", "Dv", "Ds", "Dg"],
  es_ES: ["Lu.", "Ma.", "Mi.", "Ju.", "Vi.", "Sá.", "Do."],
};

// state
export const state = {
  locale: repository.getData("locale-employee-web-app") || currentLocale,
  locales: locales,
};

// getters
export const getters = {
  locale: (state) => state.locale,
  locales: (state) => state.locales,
  dayFormat: () => {
    return "dd";
  },
  dateFormat: (state, getters) => {
    switch (getters.locale) {
      case "en":
        return "yyyy-MM-dd";
      default:
        return "dd/MM/yyyy";
    }
  },
  withoutYearFormat: (state, getters) => {
    switch (getters.locale) {
      case "en":
        return "MM-dd";
      default:
        return "dd/MM";
    }
  },
  onlyDateFormat() {
    return "dd";
  },
  getDayLong: (state, getters) => (index) => {
    return dayLong[getters.locale][index];
  },
  getDayShort: (state, getters) => (index) => {
    return dayShort[getters.locale][index];
  },
};

// mutations
export const mutations = {
  [types.SET_LOCALE](state, { locale }) {
    state.locale = locale;
  },
};

// actions
export const actions = {
  setLocale({ commit }, { locale }) {
    commit(types.SET_LOCALE, { locale });

    repository.setData("locale-employee-web-app", locale);
  },
};
