const ERROR_TYPE_INFO_COMPONENT = "error-info-component";
const INFO_TYPE_INFO_COMPONENT = "info-info-component";
const SUCCESS_TYPE_INFO_COMPONENT = "success-info-component";
const WARNING_TYPE_INFO_COMPONENT = "warning-info-component";

import InfoComponent from "@/pages/components/info/InfoComponent.vue";

export {
  ERROR_TYPE_INFO_COMPONENT,
  INFO_TYPE_INFO_COMPONENT,
  SUCCESS_TYPE_INFO_COMPONENT,
  WARNING_TYPE_INFO_COMPONENT,
  InfoComponent as default,
};
