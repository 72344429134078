import axios from "axios";
import { buildUrl } from "@/config";
import { formatDate, isset } from "@/utils/functions";

export default {
  /**
   *
   * @param {String} code
   * @param {Date} from
   * @param {Date} to
   * @returns {Promise<AxiosResponse<any>>}
   */
  getPlanHoursByEmployee({ code, from, to }) {
    const params = new URLSearchParams([
      ["EmployeeCodes", code],
      ["DateStart", formatDate(from)],
      ["DateEnd", formatDate(to)],
    ]);
    return axios.get(buildUrl(`api/v1/plan/plan-hours-by-employee`, { params }));
  },

  getPlanEmployeeType({ from, to, code }) {
    const params = new URLSearchParams([
      ["EmployeeCode", code],
      ["DateStart", formatDate(from)],
      ["DateEnd", formatDate(to)],
    ]);
    return axios.get(buildUrl(`api/v1/plan/plan-employee-type`), { params });
  },

  getPlanEmployeeTypeAll({ from, to, code = null }) {
    let params = new URLSearchParams([
      ["DateStart", formatDate(from)],
      ["DateEnd", formatDate(to)],
    ]);
    if (isset(code)) {
      params.append("EmployeeCode", code);
    }

    return axios.get(buildUrl("api/v1/plan/plan-employee-type-all"), { params });
  },

  setDayApprovedHours(data) {
    return axios.post(buildUrl("api/v1/plan/set-day-approved-hours"), data);
  },

  setDayPlanningHours(data) {
    return axios.post(buildUrl("api/v1/plan/set-day-planning-hours"), data);
  },

  setDayBiometricHours(data) {
    return axios.post(buildUrl("api/v1/plan/set-day-biometric-hours"), data);
  },

  getPlanEmployeeDetail({ from, to }) {
    const params = new URLSearchParams([
      ["DateStart", formatDate(from)],
      ["DateEnd", formatDate(to)],
    ]);
    return axios.get(buildUrl(`api/v1/plan/my-employee-detail`), { params });
  },

  /**
   *
   * @param {Date} day
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWorkShiftHourByDate({ code, day }) {
    return axios.get(buildUrl(`api/v1/plan/work-shift-hours-by-day/${code}/${formatDate(day)}`));
  },
};
