<template>
  <div class="form-group">
    <label v-if="title.length > 0">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>

    <v-select v-bind="$attrs" v-on="$listeners" v-model="form[name]">
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-select>
    <suc-field-has-error :form="form" :field="name" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Form } from "@/components/form";
import SucFieldHasError from "@/components/form/SucFieldHasError.vue";

export default {
  name: "SucSelect",
  props: {
    name: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    vSelect,
    SucFieldHasError,
  },
  data() {
    return {
      value: null,
    };
  },
};
</script>

<style lang="scss" scoped></style>
