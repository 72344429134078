import store from "@/store";

export const API_DOMAIN = process.env.VUE_APP_API_URL;

export function buildUrl(uri, params = []) {
  const appConfiguration = store.getters["auth/appConfiguration"];
  var base_url = appConfiguration?.apiEndPoint || API_DOMAIN;
  let param = params.length > 0 ? "?" + params.join("&") : "";
  return `${base_url}/${uri}${param}`;
}
