<template>
  <div class="sk-spinner sk-spinner-double-bounce">
    <div class="sk-double-bounce1"></div>
    <div class="sk-double-bounce2"></div>
  </div>
</template>

<script>
export default {
  name: "SkSpinner",
};
</script>
