import store from "../store";

// check if user is logged
export default async (to, from, next) => {
  if (!store.getters["auth/appConfigurationExists"]) {
    next({ name: "app-configuration" });
  } else if (!store.getters["auth/authenticated"] && to.path == "/recover-password") {
    next();
  } else if (!store.getters["auth/authenticated"] && to.path != "/login") {
    next({ name: "login" });
  } else {
    next();
  }
};
