import store from "@/store";
import api from "@/api/appointment";
import { STATUS_APPOINTMENT_CONFIRMWAITING } from "@/pages/components";
import { FinalAbsence, Notification, NOTIFICATION_APPOINTMENT_TYPE } from "@/pages/components/data";

export const state = {
  notifications: [],
};

export const getters = {};

export const mutations = {
  SET_NOTIFICATIONS(state, notifications) {
    state.notifications = notifications;
  },
};

function _loadConfirmAwaiting({ commit }) {
  api
    .getFilteredAbsences({
      status: STATUS_APPOINTMENT_CONFIRMWAITING,
    })
    .then((response) => {
      const appointments = response.data.map((x) => new FinalAbsence().parse(x));
      commit(
        "SET_NOTIFICATIONS",
        appointments.map((x) => new Notification({ type: NOTIFICATION_APPOINTMENT_TYPE, data: x }))
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

function _loadExceptConfirmAwating({ commit }) {
  commit("SET_NOTIFICATIONS", []);

  api
    .getMyUnreadAppointments()
    .then((response) => {
      const appointments = response.data.map((x) => new FinalAbsence().parse(x));
      commit(
        "SET_NOTIFICATIONS",
        appointments.map((x) => new Notification({ type: NOTIFICATION_APPOINTMENT_TYPE, data: x }))
      );
    })
    .catch((error) => {
      console.error(error);
    });
}

export const actions = {
  loadNotifications(module) {
    if (store.getters["auth/isCurrentRoleResponsible"]) {
      _loadConfirmAwaiting(module);
    } else {
      _loadExceptConfirmAwating(module);
    }
  },
};
