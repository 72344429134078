import LocalRepository from "../repository/local-repository";
import * as types from "../mutation-types";

import { isset, isTrue } from "@/utils/functions";

const authKey = "auth";
const userDataKey = "userData";
const tokenKey = "token";
const appConfigurationKey = "appConfiguration";
const roleKey = "role";

const roleResponsible = "responsible";
const roleEmployee = "employee";

// state
export const state = {
  auth: LocalRepository.getData(authKey, true),
  data: LocalRepository.getData(userDataKey, true),
  token: LocalRepository.getData(tokenKey),
  appConfiguration: LocalRepository.getData(appConfigurationKey, true),
  currentRole: isset(LocalRepository.getData(roleKey))
    ? LocalRepository.getData(roleKey)
    : roleEmployee,
};

// getters
export const getters = {
  authenticated: (state) => {
    return isset(state.token);
  },
  appConfigurationExists: (state) => {
    return isset(state.appConfiguration);
  },
  userName: (state) => {
    return isset(state.auth.name) ? `${state.auth.webUser}` : "";
  },
  token: (state) => state.token,
  appConfiguration: (state) => state.appConfiguration,
  isResponsible: (state) => {
    return isset(state.data) ? state.data.isResponsible : false;
  },
  isCurrentRoleResponsible: (state) => {
    return state.currentRole === roleResponsible;
  },
  isCurrentRoleEmployee: (state) => {
    return state.currentRole === roleEmployee;
  },
  logoPath: (state) => {
    return isset(state.appConfiguration) ? state.appConfiguration.logoPath : "";
  },
  userData: (state) => state.data,
  employeeCode: (state) => state.auth.employeeCode,
  webUser: (state) => state.auth.webUser,
  /**
   * Holiday code in company configuration
   *
   * @return {Boolean}
   */
  holidaysAbsenceCode: (state) => {
    return isset(state.data) && isset(state.data.companySetup)
      ? state.data.companySetup.holidaysAbsenceCode
      : null;
  },
  /**
   * Is enabled hours pack option
   *
   * @return {Boolean}
   */
  activeHoursPack: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.hoursBalance) &&
      isTrue(state.data.companySetup.showEmployeeHoursBalance)
      ? true
      : false;
  },
  /**
   * Is enabled payrolls
   *
   * @return {Boolean}
   */
  activeEmployeePayrolls: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.activeEmployeePayrolls)
      ? true
      : false;
  },
  /**
   * Is enabled permissons
   *
   * @return {Boolean}
   */
  activeEmployeePermissions: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.activeEmployeePermissions)
      ? true
      : false;
  },
  /**
   * Is enabled planning
   *
   * @return {Boolean}
   */
  activeEmployeePlanning: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.activeEmployeePlanning)
      ? true
      : false;
  },
  /**
   * Show personal data
   *
   * @return {Boolean}
   */
  showEmployeePersonalData: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.showEmployeePersonalData)
      ? true
      : false;
  },
  /**
   * Use hours from workShift to create absence
   *
   * @return {Boolean}
   */
  absenceFromWorkShift: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.absenceFromWorkshift)
      ? true
      : false;
  },
  /**
   * Show biometric option
   *
   * @return {Boolean}
   */
  activeBiometric: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.activeBiometric)
      ? true
      : false;
  },
  /**
   * Show hour to approve
   *
   * @return {Boolean}
   */
  activeHourForApprove: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.activeHourApr)
      ? true
      : false;
  },
  /**
   * Show workshift name in planificaton
   *
   * @return {Boolean}
   */
  showWorkshiftName: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.showWorkshiftName)
      ? true
      : false;
  },
  canEditGeneral: (_, getters) => {
    return (
      getters.canEditName ||
      getters.canEditFirstName ||
      getters.canEditSecondName ||
      getters.canEditGender ||
      getters.canEditEmployeeCompanyPhone ||
      getters.canEditEmail
    );
  },
  canEditName: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editName)
      ? true
      : false;
  },
  canEditFirstName: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editFirstName)
      ? true
      : false;
  },
  canEditSecondName: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editSecondName)
      ? true
      : false;
  },
  canEditGender: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editGender)
      ? true
      : false;
  },
  canEditEmployeeCompanyPhone: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editEmployeeCompanyPhone)
      ? true
      : false;
  },
  canEditEmail: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editEmail)
      ? true
      : false;
  },
  canDirectionContact: (_, getters) => {
    return (
      getters.canEditAddress ||
      getters.canEditAddress2 ||
      getters.canEditCodePostal ||
      getters.canEditCity ||
      getters.canEditCountry ||
      getters.canEditPersonalPhone ||
      getters.canEditPhoneExt ||
      getters.canEditPersonalEmail
    );
  },
  canEditAddress: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editAddress)
      ? true
      : false;
  },
  canEditAddress2: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editAddress2)
      ? true
      : false;
  },
  canEditCodePostal: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editCP)
      ? true
      : false;
  },
  canEditCity: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editCity)
      ? true
      : false;
  },
  canEditCountry: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editCountry)
      ? true
      : false;
  },
  canEditPersonalPhone: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPersonalPhone)
      ? true
      : false;
  },
  canEditPhoneExt: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPhoneExt)
      ? true
      : false;
  },
  canEditPersonalEmail: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPersonalEmail)
      ? true
      : false;
  },
  canEditPersonal: (_, getters) => {
    return (
      getters.canEditCass ||
      getters.canEditResidence ||
      getters.canEditCassExpiration ||
      getters.canEditPassport ||
      getters.canEditPassportExp ||
      getters.canEditNationality ||
      getters.canEditBornDate
    );
  },
  canEditCass: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editCASS)
      ? true
      : false;
  },
  canEditResidence: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editResidence)
      ? true
      : false;
  },
  canEditCassExpiration: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editCASSExpiration)
      ? true
      : false;
  },
  canEditPassport: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPassport)
      ? true
      : false;
  },
  canEditPassportExp: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPassportExp)
      ? true
      : false;
  },
  canEditNationality: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editNationality)
      ? true
      : false;
  },
  canEditBornDate: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editBornDate)
      ? true
      : false;
  },
  canEditPayment: (_, getters) => {
    return getters.canEditPaymentIBan || getters.canEditPaymentSwift;
  },
  canEditPaymentIBan: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPaymentIBAN)
      ? true
      : false;
  },
  canEditPaymentSwift: (state) => {
    return isset(state.data) &&
      isset(state.data.companySetup) &&
      isTrue(state.data.companySetup.editPaymentSWIFT)
      ? true
      : false;
  },
};

// mutations
export const mutations = {
  [types.SAVE_AUTH_DATA](state, data) {
    let auth = {
      employeeCode: data.employeeCode,
      isResponsible: data.isResponsible,
      key: data.key,
      name: data.name,
      role: data.role,
      roleDescription: data.roleDescription,
      webUser: data.webUser,
    };
    state.token = data.token;
    state.auth = auth;

    LocalRepository.setData(authKey, auth, true);
    LocalRepository.setData(tokenKey, data.token);
  },
  [types.SAVE_USER_DATA](state, data) {
    state.data = data;
    LocalRepository.setData(userDataKey, data, true);
  },
  [types.SAVE_APP_CONFIGURATION_DATA](state, data) {
    let conf = {
      code: data.code,
      apiEndPoint: data.apiEndPoint,
      logoPath: data.logoPath,
    };
    state.appConfiguration = conf;
    LocalRepository.setData(appConfigurationKey, conf, true);
  },
  [types.REMOVE_USER](state) {
    LocalRepository.removeData(authKey);
    LocalRepository.removeData(tokenKey);
    LocalRepository.removeData(userDataKey);
    LocalRepository.removeData(roleKey);
    LocalRepository.removeData(appConfigurationKey);
    state.user = state.token = state.data = state.appConfiguration = null;
  },
  [types.REMOVE_APP_CONFIGURATION](state) {
    LocalRepository.removeData(appConfigurationKey);
    state.appConfiguration = null;
  },
  [types.CHANGE_TO_EMPLOYEE_ROLE](state) {
    state.currentRole = roleEmployee;
    LocalRepository.setData(roleKey, roleEmployee);
  },
  [types.CHANGE_TO_RESPONSIBLE_ROLE](state) {
    state.currentRole = roleResponsible;
    LocalRepository.setData(roleKey, roleResponsible);
  },
};

// actions
export const actions = {
  saveAuth({ commit }, payload) {
    commit(types.SAVE_AUTH_DATA, payload);
  },

  saveAppConfiguration({ commit }, payload) {
    commit(types.SAVE_APP_CONFIGURATION_DATA, payload);
  },

  saveData({ commit }, payload) {
    commit(types.SAVE_USER_DATA, payload);
  },

  changeToResponsibleRole({ commit }) {
    commit(types.CHANGE_TO_RESPONSIBLE_ROLE);
  },

  changeToEmployee({ commit }) {
    commit(types.CHANGE_TO_EMPLOYEE_ROLE);
  },

  removeUser({ commit }) {
    commit(types.REMOVE_USER);
  },

  removeAppConfiguration({ commit }) {
    commit(types.REMOVE_APP_CONFIGURATION);
  },
};
