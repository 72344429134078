<template>
  <div class="card card-data">
    <div class="card-header">
      <nav class="navbar">
        <ul class="nav">
          <li
            class="nav-item tab-item"
            v-for="(tab, index) in tabs"
            :key="`${tab}_${index}`"
            @click="setActive(tab)"
          >
            <a
              class="nav-link"
              :class="{ active: $isset(currentTab) && currentTab.key === tab.key }"
              :href="`#${currentTab.key}`"
              >{{ tab.name }}</a
            >
          </li>
        </ul>
      </nav>
      <hr class="rounded mx-3" />
    </div>
    <div class="card-body data-load" :class="{ 'sk-loading': isLoading }">
      <sk-spinner v-if="isLoading"></sk-spinner>
      <slot name="data" :tab="currentTab"> </slot>
    </div>
  </div>
</template>

<script>
import { Tab, CARD_DATA_TAB_CHANGED } from "./index";
import { SkSpinner } from "@/components";

export default {
  name: "CardDataComponent",
  props: {
    tabs: {
      type: Array,
      required: true,
      validator: function (tabs) {
        for (let tab of tabs) {
          if (!(tab instanceof Tab)) {
            console.error("tabs should include only Tab instance");
            return false;
          }
          return true;
        }
      },
    },
    currentTabKey: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SkSpinner,
  },
  computed: {
    currentTab() {
      let tab = this.tabs.find((x) => x.key === this.currentTabKey);
      return this.$isset(tab) ? tab : this.tabs[0];
    },
  },
  methods: {
    setActive(tab) {
      this.$emit(CARD_DATA_TAB_CHANGED, tab);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-data {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.2);

  & > .card-header {
    background-color: transparent;
    padding: 0;
    border-bottom: 0;
  }

  & > .card-header > .navbar {
    padding-bottom: 0;
  }

  .tab-item {
    & > a {
      color: $color-gray;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
    }

    & > a:hover {
      color: #333333;
      border-bottom: #0b2e13;
    }

    & > .active {
      color: $color-secondary;
      border-bottom: 3px solid $color-secondary !important;
    }
  }
}

hr.rounded {
  padding: 0;
  margin: -3px 0 0 0;
  height: 3px;
  border-radius: 15.5px;
  background-color: $color-gray-2;
}
</style>
