<template>
  <div class="form-check">
    <input
      class="form-check-input"
      :class="labelClasses"
      type="checkbox"
      :id="id"
      v-model="form[name]"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <label class="form-check-label" :for="id">
      {{ title }}
    </label>
  </div>
</template>

<script>
import { Form } from "./index";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    labelClasses: {
      type: [Object, Array, String],
      default: () => {},
    },
    valueClasses: {
      type: [Object, Array, String],
      default: () => {},
    },
  },
};
</script>

<style></style>
