<template>
  <div>
    <img src="@/assets/IC_Arrow_Left_pink_secondary.svg" alt="back" @click="backPeriod" />

    <template v-if="isWeek">
      <formatted-date-component :val="start" :without-year="true" />
      <span class="mx-1">{{ $t("components.fields.to") }}</span>
      <formatted-date-component :val="end" :without-year="true" />
    </template>

    <template v-else-if="isMonth">
      <span class="mx-1 month">{{
        $t(`components.date.month_long[${start.getMonth()}]`) + ` ${start.getFullYear()}`
      }}</span>
    </template>

    <template v-else>
      <span class="mx-1">{{ $formatDate(this.start) }}</span>
    </template>

    <img
      src="@/assets/IC_Arrow_Right_pink_secondary.svg"
      alt="next"
      class="ml-1"
      @click="nextPeriod"
    />
  </div>
</template>

<script>
import { addMonths, endOfWeek, startOfWeek, addDays, startOfMonth, endOfMonth } from "date-fns";

import { FormattedDateComponent } from "@/components/formatted";
import { SUC_DATE_PERIOD_CHANGED_EVENT, SUC_DATE_PERIOD_SET_DATE_EVENT } from "./index";

import { DATE_PERIOD_WEEK, DATE_PERIOD_MONTH, DATE_PERIOD_DAY } from "@/components/form";

export default {
  name: "SucDatePeriodComponent",
  components: {
    FormattedDateComponent,
  },
  props: {
    type: {
      type: String,
      validator: function (val) {
        const types = [DATE_PERIOD_DAY, DATE_PERIOD_WEEK, DATE_PERIOD_MONTH];
        if (!types.includes(val)) {
          console.error("type shoud be one of", types);
          return false;
        }
        return true;
      },
      default: DATE_PERIOD_WEEK,
    },
  },
  data() {
    return {
      initialDate: null, //use this date to set custom period by global event
      start: null,
      end: null,
      options: { weekStartsOn: 1 },
    };
  },
  computed: {
    isWeek() {
      return this.type === DATE_PERIOD_WEEK;
    },
    isMonth() {
      return this.type === DATE_PERIOD_MONTH;
    },
    isDay() {
      return this.type === DATE_PERIOD_DAY;
    },
    initDate() {
      return this.$isset(this.initialDate) ? this.initialDate : new Date();
    },
  },
  watch: {
    type() {
      this.currentPeriod();
    },
  },
  created() {
    this.currentPeriod();
    EventBus.listen(SUC_DATE_PERIOD_SET_DATE_EVENT, ({ date }) => {
      this.initialDate = date;
      this.currentPeriod();
    });
  },
  beforeDestroy() {
    EventBus.off(SUC_DATE_PERIOD_SET_DATE_EVENT);
  },
  methods: {
    currentPeriod() {
      if (this.isWeek) {
        this._currentWeek();
      } else if (this.isMonth) {
        this._currentMonth();
      } else {
        this._currentDay();
      }
      this._fireEvent();
      this.initialDate = null;
    },

    nextPeriod() {
      if (this.isWeek) {
        this._nextWeek();
      } else if (this.isMonth) {
        this._nextMonth();
      } else {
        this._nextDay();
      }
      this._fireEvent();
    },

    backPeriod() {
      if (this.isWeek) {
        this._lastWeek();
      } else if (this.isMonth) {
        this._lastMonth();
      } else {
        this._lastDay();
      }
      this._fireEvent();
    },
    _fireEvent() {
      this.$emit(SUC_DATE_PERIOD_CHANGED_EVENT, {
        start: this.start,
        end: this.end,
        type: this.type,
      });
    },
    _currentDay() {
      this.start = this.end = this.initDate;
    },
    _nextDay() {
      this.start = this.end = addDays(this.start, 1);
    },
    _lastDay() {
      this.start = this.end = addDays(this.start, -1);
    },
    _currentWeek() {
      this.start = startOfWeek(this.initDate, this.options);
      this.end = endOfWeek(this.initDate, this.options);
    },
    _nextWeek() {
      this.start = addDays(this.start, 7);
      this.end = endOfWeek(this.start, this.options);
    },
    _lastWeek() {
      this.start = addDays(this.start, -7);
      this.end = endOfWeek(this.start, this.options);
    },
    _currentMonth() {
      this.start = startOfMonth(this.initDate);
      this.end = endOfMonth(this.initDate);
    },
    _nextMonth() {
      this.start = addMonths(this.start, 1);
      this.end = endOfMonth(this.start);
    },
    _lastMonth() {
      this.start = addMonths(this.start, -1);
      this.end = endOfMonth(this.start);
    },
  },
};
</script>

<style scoped>
.month {
  text-transform: capitalize;
}
</style>
