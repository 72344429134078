import axios from "axios";
import store from "@/store";
import { v4 as uuidv4 } from "uuid";

axios.interceptors.request.use((request) => {
  const locale = store.getters["lang/locale"];
  if (locale) {
    request.headers.common["X-Accept-Language"] = locale;
  }

  const appConfiguration = store.getters["auth/appConfiguration"];
  if (appConfiguration) {
    request.headers.common["X-Code-Company"] = appConfiguration.code;
  }

  const token = store.getters["auth/token"];
  if (token) {
    request.headers.common["Authorization"] = `Bearer ${token}`;
  }

  request.headers.common["X-Correlation-Id"] = uuidv4();
  return request;
});
