<template>
  <div class="row footer">
    <div class="col text-center">
      <div>{{ copyright }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["appVersion", "copyright"]),
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $color-primary;
}

.footer div {
  color: white;
  padding: 8px;
}
</style>
