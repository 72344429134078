function page(path) {
  return () => import(/* webpackChunkName: '' */ `../pages/${path}`).then((m) => m.default || m);
}

export default [
  { path: "/", name: "dashboard", component: page("dashboard/DashBoardPage.vue") },
  { path: "/profile", name: "profile", component: page("profile/ProfilePage.vue") },
  { path: "/payroll", name: "payroll", component: page("employee/payroll/PayRollPage.vue") },
  {
    path: "/payroll-employees",
    name: "payroll-employees",
    component: page("responsible/payroll/PayRollPage.vue"),
  },
  {
    path: "/permission",
    name: "permission",
    component: page("employee/permission/PermissionPage.vue"),
  },
  {
    path: "/app-configuration",
    name: "app-configuration",
    component: page("auth/AppConfigurationPage.vue"),
  },
  { path: "/login", name: "login", component: page("auth/LoginPage.vue") },
  {
    path: "/recover-password",
    name: "recover-password",
    component: page("auth/RecoverPasswordPage.vue"),
  },
  {
    path: "/planning",
    name: "planning",
    component: page("employee/planning/PlanningPage.vue"),
  },
  // responsible
  {
    path: "/planing-team",
    name: "planing-team",
    component: page("responsible/planing/PlaningTeamPage.vue"),
  },
  {
    path: "/planing-team-validate/:code",
    name: "planing-team-validate",
    component: page("responsible/planing/validate/PlaningTeamValidatePage.vue"),
    props: true,
  },
  {
    path: "/profile-employee/:code",
    name: "profile-employee",
    component: page("responsible/profile/ProfileEmployeePage.vue"),
    props: true,
  },
];
