<template>
  <button class="btn" type="button" :disabled="disabled" v-bind="$attrs" v-on="$listeners">
    <span
      class="spinner-border spinner-border-sm mr-2"
      role="status"
      aria-hidden="true"
      v-if="loading"
    ></span>
    <slot name="body">
      <slot name="body-icon"></slot>
      <slot name="body-text">
        <span class="p-2" :class="{ uppercase: uppercase }">{{ title }}</span>
      </slot>
    </slot>
  </button>
</template>

<script>
export default {
  name: "VueButton",
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Button",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    disabled() {
      return this.disable || this.loading;
    },
  },
};
</script>

<style scoped>
.uppercase {
  text-transform: uppercase;
}
</style>
