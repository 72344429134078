import VueButton from "@/components/form/VueButton.vue";
import SucSelectComponent from "@/components/form/SucSelectComponent.vue";
import SucDatePeriodComponent from "@/components/form/SucDatePeriodComponent.vue";
import SucPeriodComponent from "@/components/form/SucPeriodComponent.vue";
import SucTextFieldComponent from "@/components/form/SucTextFieldComponent.vue";
import SucTextAreaFieldComponent from "@/components/form/SucTextAreaFieldComponent.vue";
import SucDateTimeField from "@/components/form/SucDateTimeField.vue";
import SucRadioFieldComponent from "@/components/form/SucRadioFieldComponent.vue";
import SucTextFormShowField from "@/components/form/SucTextFormShowField.vue";
import SucFileFieldComponent from "@/components/form/SucFileFieldComponent.vue";
import SucFieldHasError from "@/components/form/SucFieldHasError.vue";
import SucFormHasError from "@/components/form/SucFormHasError.vue";
import SucCheckboxFieldComponent from "@/components/form/SucCheckboxFieldComponent.vue";

import {
  SUC_SELECT_CHANGED_EVENT,
  SUC_DATE_PERIOD_CHANGED_EVENT,
  SUC_PERIOD_CHANGED_EVENT,
  SUC_DATE_PERIOD_SET_DATE_EVENT,
  SUC_RADIO_CHANGED_EVENT,
} from "./data/events";
import Form from "./data/Form";
import Options from "./data/Options";
import Errors from "./data/Errors";
import RadioOption from "./data/RadioOption";

const DATE_PERIOD_DAY = "date-period-day";
const DATE_PERIOD_WEEK = "date-period-week";
const DATE_PERIOD_MONTH = "date-period-month";

const FORM_ERRORS = "form-errors";

export {
  Form,
  Errors,
  Options,
  RadioOption,
  VueButton,
  SucSelectComponent,
  SucDatePeriodComponent,
  SucPeriodComponent,
  SucTextFieldComponent,
  SucTextAreaFieldComponent,
  SucDateTimeField,
  SucRadioFieldComponent,
  SucTextFormShowField,
  SucFileFieldComponent,
  SucFieldHasError,
  SucFormHasError,
  SucCheckboxFieldComponent,
  SUC_SELECT_CHANGED_EVENT,
  SUC_RADIO_CHANGED_EVENT,
  SUC_PERIOD_CHANGED_EVENT,
  SUC_DATE_PERIOD_CHANGED_EVENT,
  SUC_DATE_PERIOD_SET_DATE_EVENT,
  DATE_PERIOD_DAY,
  DATE_PERIOD_WEEK,
  DATE_PERIOD_MONTH,
  FORM_ERRORS,
};
