/**
 * Deep copy the given object.
 *
 * @param  {Object} obj
 * @return {Object}
 */
export function deepCopy(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  const copy = Array.isArray(obj) ? [] : {};

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key]);
  });

  return copy;
}

/**
 * Check if value is false
 *
 * @param  {String|Boolean} value
 * @return {Boolean}
 */
export function isFalse(value) {
  return value === "false" || value === false;
}

/*
 * Check if value is false
 *
 * @param  {String|Boolean} value
 * @return {Boolean}
 */
export function isTrue(value) {
  return value === "true" || value === true;
}

/**
 * If the given value is not an array, wrap it in one.
 *
 * @param  {Any} value
 * @return {Array}
 */
export function arrayWrap(value) {
  return Array.isArray(value) ? value : [value];
}

/**
 * Parse string and return date
 * Function created because in safari browser, parsing via Date constructor doesnt work.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date

 * @param  {String} value
 * @return {Date}
 */
export function parseDate(value) {
  if (isset(value)) {
    value = value.trim().replace("Z", "");
    const parts = value.split("T");
    if (value.length === 19 && value !== "0001-01-01T00:00:00") {
      if (parts.length === 2) {
        const dateParts = parts[0].split("-");
        const timeParts = parts[1].split(":");

        const year = parseInt(dateParts[0]);
        const monthIndex = parseInt(dateParts[1]) - 1; //monthIndex
        const day = parseInt(dateParts[2]);

        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);
        const seconds = parseInt(timeParts[1]);

        return new Date(year, monthIndex, day, hours, minutes, seconds);
      }
    } else if (value.length === 10 && value !== "0001-01-01") {
      const dateParts = parts[0].split("-");

      const year = parseInt(dateParts[0]);
      const monthIndex = parseInt(dateParts[1]) - 1; //monthIndex
      const day = parseInt(dateParts[2]);

      return new Date(year, monthIndex, day);
    }
  }
  return null;
}

export function isset(val) {
  return (
    val !== undefined &&
    val !== null &&
    val !== "0001-01-01T00:00:00" &&
    val !== "0001-01-01" &&
    val !== "00:00:00"
  );
}

export function parseISOElseNull(val) {
  return isset(val) ? parseISO(val) : null;
}

export function parseTimeElseNull(val) {
  return val !== undefined && val !== null ? parse(val, "HH:mm:ss", new Date()) : null;
}

/**
 *
 * @param {Date} value
 */
export function formatDateTime(value) {
  return (
    value.getFullYear() +
    "-" +
    appendLeadingZeroes(value.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(value.getDate()) +
    " " +
    appendLeadingZeroes(value.getHours()) +
    ":" +
    appendLeadingZeroes(value.getMinutes()) +
    ":" +
    appendLeadingZeroes(value.getSeconds())
  );
}

/**
 *
 * @param {Date} value
 */
export function formatDate(value) {
  return (
    value.getFullYear() +
    "-" +
    appendLeadingZeroes(value.getMonth() + 1) +
    "-" +
    appendLeadingZeroes(value.getDate())
  );
}

/**
 *
 * @param {Date} value
 * @param {Boolean} seconds
 */
export function formatTime(value, seconds = false) {
  let result =
    appendLeadingZeroes(value.getHours()) + ":" + appendLeadingZeroes(value.getMinutes());
  if (seconds) {
    result = result + ":" + appendLeadingZeroes(value.getSeconds());
  }
  return result;
}

function appendLeadingZeroes(n) {
  if (n <= 9) {
    return "0" + n;
  }
  return n;
}

/**
 *
 * @param {Date} value
 * @return {Number} week index, 0 is monday
 */
export function getDay(value) {
  let index = value.getDay();
  switch (index) {
    case 0:
      return 6;
    default:
      return index - 1;
  }
}

export default class DetailHour {
  /**
   * Detail of the hour
   *
   * @param {Object} param
   * @param {Date} param.hour hour of the day
   * @param {Date} param.defined indicator, if the hour is used and has half of hour. 0 - unused, 0.3 - has half of the hour (09:30), 1 - full hour (09:00)
   */
  constructor({ hour, defined = 0 }) {
    this.hour = hour;
    this.defined = defined;
  }
}

export function create24DayHours() {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    hours.push(new DetailHour({ hour: new Date(0, 0, 0, i, 0, 0) }));
  }
  return hours;
}

import { differenceInHours, addHours, parseISO, parse } from "date-fns";

/**
 *  Convert date hours to fill list day hours
 *
 * @param {Object} param
 * @param {Array<DetailHour>} param.hour list of the day hours
 * @param {Date} param.startHour starting hour
 * @param {Date} param.endHour ending hour
 *
 */
export function fillDayHours({ hours = create24DayHours(), startHour, endHour }) {
  if (isset(endHour) && isset(startHour)) {
    let diffStartingHours = differenceInHours(endHour, startHour);
    if (startHour.getMinutes() > 0 && endHour.getMinutes() == 0)
      diffStartingHours = diffStartingHours + 1;

    let initialHour = new Date(0, 0, 0, startHour.getHours(), 0, 0);
    for (let i = 0; i <= diffStartingHours; i++) {
      let currentHour = addHours(initialHour, i);
      let hour = hours.find((x) => x.hour.getHours() == currentHour.getHours());
      hour.defined = currentHour.getMinutes() > 0 ? 0.3 : 1;
      //first hour can be half of hour, for example 9.30
      if (i == 0 && startHour.getMinutes() > 0) {
        hour.defined = 0.3;
      } else if (i == diffStartingHours) {
        //last hour can be half of hour, for example 10.30
        hour.defined = endHour.getMinutes() > 0 ? 0.3 : 0;
      }
    }
  }
  return hours;
}

export function sum(a, b) {
  return a + b;
}

export function copyMinutesToHoursField(totalMinutes) {
  let hours = parseInt(totalMinutes / 60);
  let minutes = +((totalMinutes % 60) / 60).toFixed(2);
  let hourValue = hours + minutes;
  return hourValue;
}
