export default class Attachment {
  parse(data) {
    this.fileExtension = data.fileExtension;
    this.name = data.name;
    this.type = data.type;
    this.reference = data.reference;
    this.employeeCode = data.employeeCode;

    return this;
  }
}
