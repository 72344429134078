import { isset, parseISOElseNull } from "@/utils/functions";

import {
  VACATIONS_TYPE_FILTER_APPROVED,
  VACATIONS_TYPE_FILTER_CONFIRMWAITING,
  VACATIONS_TYPE_FILTER_NEED_REUNION,
  VACATIONS_TYPE_FILTER_REJECTED,
  ABSENCE_TYPE_FILTER_SICK,
  ABSENCE_TYPE_FILTER_JUSTIFIED,
  ABSENCE_TYPE_FILTER_UNJUSTIFIED,
  ABSENCE_TYPE_FILTER_REJECTED,
  ABSENCE_TYPE_FILTER_CONFIRMWAITING,
  SOURCE_FINAL_ABSENCE_PERMISSION,
  ABSENCE_TYPE_FILTER_NEED_REUNION,
} from "@/pages/components/";
import { Attachment } from "./index";
import {
  STATUS_APPOINTMENT_APPROVED,
  STATUS_APPOINTMENT_NEEDREUNION,
  STATUS_APPOINTMENT_REJECTED,
} from "../constants";

export default class FinalAbsence {
  parse(data) {
    this.comments = data.comments;
    this.dateEnd = parseISOElseNull(data.dateEnd);
    this.dateStart = parseISOElseNull(data.dateStart);
    this.employeeName = data.employeeName;
    this.employeeCode = data.employeeCode;
    this.employeeRead = data.employeeRead;
    this.sourceCode = data.sourceCode;
    this.sourceDescription = data.sourceDescription;
    this.typeFilter = data.typeFilter;
    this.causeOfAbsenceCode = data.causeOfAbsenceCode;
    this.causeOfAbsenceDescription = data.causeOfAbsenceDescription;
    this.statusCode = data.statusCode;
    this.statusDescription = data.statusDescription;
    this.id = data.id;
    this.attachments = [];

    if (isset(data.attachments)) {
      this.attachments = data.attachments.map((x) => new Attachment().parse(x));
    }

    return this;
  }

  isVacationsRejected() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_REJECTED;
  }

  isVacationsNeedMeeting() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_NEED_REUNION;
  }

  isVacationsConfirmWaiting() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_CONFIRMWAITING;
  }

  isVacationsApproved() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_APPROVED;
  }

  isTypeFilterSick() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_SICK;
  }

  isAbsenceJustified() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_JUSTIFIED;
  }

  isAbsenceUnjustified() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_UNJUSTIFIED;
  }

  isAbsenceRejected() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_REJECTED;
  }

  isAbsenceConfirmWaiting() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_CONFIRMWAITING;
  }

  isAbsenceNeedMeeting() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_NEED_REUNION;
  }

  isPermission() {
    return this.sourceCode == SOURCE_FINAL_ABSENCE_PERMISSION;
  }

  isConfirmWaiting() {
    return this.isAbsenceConfirmWaiting() || this.isVacationsConfirmWaiting();
  }

  /**
   * For all absense, except appointment status is approved by default
   */
  isStatusApproved() {
    return this.statusCode === STATUS_APPOINTMENT_APPROVED;
  }

  /**
   * Only appointment can have this status
   */
  isStatusRejected() {
    return this.statusCode === STATUS_APPOINTMENT_REJECTED;
  }

  /**
   * Only appointment can have this status
   */
  isStatusNeedReuniton() {
    this.statusCode === STATUS_APPOINTMENT_NEEDREUNION;
  }

  canRemove() {
    return (
      this.isPermission() && (this.isAbsenceConfirmWaiting() || this.isVacationsConfirmWaiting())
    );
  }
}
