<template>
  <div class="form-group">
    <div class="row" v-if="title.length > 0">
      <div class="col-md-12">
        <label :for="id">{{ title }}</label>
        <span class="text-danger" v-if="required"> *</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <date-picker
          v-model="form[name]"
          :type="withTime ? 'datetime' : 'date'"
          :placeholder="formatPlaceHolder"
          :show-time-panel="showTimePanel"
          @close="handleOpenChange"
          input-class="form-control"
          :lang="language"
          :format="format"
          :range="range"
          :range-separator="rangeSeparator"
          :show-week-number="true"
          :confirm="confirm"
        >
          <template v-slot:footer v-if="withTime">
            <button class="mx-btn mx-btn-text" @click="toggleTimePanel">
              {{ showTimePanel ? $t("fields.select_date") : $t("fields.select_time") }}
            </button>
          </template>
        </date-picker>
        <suc-field-has-error :form="form" :field="name" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { mapGetters } from "vuex";

import { Form } from "@/components/form";
import SucFieldHasError from "@/components/form/SucFieldHasError.vue";

import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from "date-fns";

export default {
  name: "VueMyDateTimeField",
  props: {
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    range: {
      type: Boolean,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatePicker,
    SucFieldHasError,
  },
  data() {
    return {
      showTimePanel: false,
      showTimeRangePanel: false,

      lang: {
        en: {
          placeholders: {
            YYYY: "YYYY",
            MM: "MM",
            DD: "DD",
            HH: "HH",
            mm: "mm",
          },
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            // MMM
            monthsShort: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            // dddd
            weekdays: [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ],
            // ddd
            weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            // dd
            weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            // first day of week
            firstDayOfWeek: 1,
            // first week contains January 1st.
            firstWeekContainsDate: 1,
            // format 'a', 'A'
          },
          // the calendar title of year
          yearFormat: "YYYY",
          // the calendar title of month
          monthFormat: "MMM",
          // the calendar title of month before year
          monthBeforeYear: true,
        },
        ca_ES: {
          placeholders: {
            YYYY: "yyyy",
            MM: "MM",
            DD: "dd",
            HH: "HH",
            mm: "mm",
          },
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: [
              "Gener",
              "Febrer",
              "Març",
              "Abril",
              "Maig",
              "Juny",
              "Juliol",
              "Agost",
              "Setembre",
              "Octubre",
              "Novembre",
              "Desembre",
            ],
            // MMM
            monthsShort: [
              "Gen.",
              "Feb.",
              "Mar.",
              "Abr.",
              "Maig",
              "Jun.",
              "Jul.",
              "Ago.",
              "Set.",
              "Oct.",
              "Nov.",
              "Des.",
            ],
            // dddd
            weekdays: [
              "Diumenge",
              "Dilluns",
              "Dimarts",
              "Dimecres",
              "Dijous",
              "Divendres",
              "Dissabte",
            ],
            // ddd
            weekdaysShort: ["Dg", "Dl", "Dt", "Dc", "Dj", "Dv", "Ds"],
            // dd
            weekdaysMin: ["Dg", "Dl", "Dt", "Dc", "Dj", "Dv", "Ds"],
            // first day of week
            firstDayOfWeek: 1,
            // first week contains January 1st.
            firstWeekContainsDate: 1,
            // format 'a', 'A'
          },
          // the calendar title of year
          yearFormat: "YYYY",
          // the calendar title of month
          monthFormat: "MMM",
          // the calendar title of month before year
          monthBeforeYear: true,
        },
        es_ES: {
          placeholders: {
            YYYY: "yyyy",
            MM: "MM",
            DD: "dd",
            HH: "HH",
            mm: "mm",
          },
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: [
              "Enero",
              "Febrero",
              "Marzo",
              "Abril",
              "Mayo",
              "Junio",
              "Julio",
              "Agosto",
              "Septiembre",
              "Octubre",
              "Noviembre",
              "Diciembre",
            ],
            // MMM
            monthsShort: [
              "Ene.",
              "Feb.",
              "Mar.",
              "Abr.",
              "May.",
              "Jun.",
              "Jul.",
              "Ago.",
              "Sep.",
              "Oct.",
              "Nov.",
              "Dic.",
            ],
            // dddd
            weekdays: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
            // ddd
            weekdaysShort: ["Do.", "Lu.", "Ma.", "Mi.", "Ju.", "Vi.", "Sá."],
            // dd
            weekdaysMin: ["Do.", "Lu.", "Ma.", "Mi.", "Ju.", "Vi.", "Sá."],
            // first day of week
            firstDayOfWeek: 1,
            // first week contains January 1st.
            firstWeekContainsDate: 1,
            // format 'a', 'A'
          },
          // the calendar title of year
          yearFormat: "YYYY",
          // the calendar title of month
          monthFormat: "MMM",
          // the calendar title of month before year
          monthBeforeYear: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    shortcuts() {
      const ext = this;
      let shortcuts = [
        {
          text: this.withTime ? this.$t("datetime.now") : this.$t("datetime.today"),
          onClick() {
            return ext.range ? [new Date(), new Date()] : new Date();
          },
        },
        {
          text: this.$t("datetime.yesterday"),
          onClick() {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);

            return ext.range ? [date, date] : date;
          },
        },
      ];
      //week ago
      //month ago
      if (this.range) {
        //this week
        shortcuts.push({
          text: this.$t("datetime.week"),
          onClick() {
            const date = new Date();
            const date1 = startOfWeek(date, { weekStartsOn: 1 });
            const date2 = endOfWeek(date, { weekStartsOn: 1 });

            return [date1, date2];
          },
        });
        //this month
        shortcuts.push({
          text: this.$t("datetime.month"),
          onClick() {
            const date = new Date();
            const date1 = startOfMonth(date);
            const date2 = endOfMonth(date);

            return [date1, date2];
          },
        });
      }
      return shortcuts;
    },
    language() {
      return this.lang[this.locale];
    },
    format() {
      let format = this.getDateFormat();
      if (this.withTime) {
        format = `${this.getDateFormat()} ${this.getTimeFormat()}`;
      }
      return format;
    },
    formatPlaceHolder() {
      let format = this.format;
      Object.keys(this.lang[this.locale]["placeholders"]).forEach((key) => {
        format = format.replace(key, this.lang[this.locale]["placeholders"][key]);
      });
      if (this.range) {
        format = `${format}${this.rangeSeparator}${format}`;
      }
      return format;
    },
    rangeSeparator() {
      return " - ";
    },
  },
  methods: {
    getDateFormat() {
      switch (this.locale) {
        case "en":
          return "YYYY-MM-DD";
        default:
          return "DD/MM/YYYY";
      }
    },
    getTimeFormat() {
      return this.withTime ? " HH:mm" : "";
    },
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
  },
};
</script>

<style>
.mx-table-date .today {
  border: 1px solid #1284e7;
  color: #2a90e9;
}
.mx-datepicker {
  min-width: 200px;
  width: 100%;
}
.mx-datepicker-range {
  width: 100%;
}
</style>
