require("./bootstrap");

import Vue from "vue";
import router from "@/router";
import store from "@/store";
import App from "@/App.vue";
import i18n from "@/plugins/i18n";

import "@/plugins";

import "bootstrap";

import EventBus from "./components/EventBus";
import VueSignature from "vue-signature-pad";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

window.EventBus = new EventBus();

require("./sentry");

import VueMyToasts from "vue-my-toasts";
import "vue-my-toasts/dist/vue-my-toasts.css";
import BootstrapComponent from "vue-my-toasts/src/components/toasts/BootstrapComponent";

Vue.use(VueMyToasts, {
  // component: BootstrapComponent,
  component: BootstrapComponent,
  options: {
    width: "400px",
    position: "top-right",
    padding: "1rem",
  },
});

import {
  isset,
  deepCopy,
  formatDate,
  formatDateTime,
  formatTime,
  parseISOElseNull,
  parseTimeElseNull,
} from "@/utils/functions";

Vue.prototype.$isset = function (value) {
  return isset(value);
};
Vue.prototype.$deepCopy = function (value) {
  return deepCopy(value);
};
Vue.prototype.$parseDate = function (value) {
  return parseISOElseNull(value);
};
Vue.prototype.$parseTime = function (value) {
  return parseTimeElseNull(value);
};
Vue.prototype.$formatDate = function (value) {
  return formatDate(value);
};
Vue.prototype.$formatDateTime = function (value) {
  return formatDateTime(value);
};
Vue.prototype.$formatTime = function (value) {
  return formatTime(value);
};

Vue.use(VueSignature);
Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  ...App,
});
