export default class EmployeeOvertime {
  parse(data) {
    this.isNight = data.isNight;
    this.establishmentCode = data.establishmentCode;
    this.processed = data.processed;
    this.qtyExtra = data.qtyExtra;

    return this;
  }
}
