import Swal from "sweetalert2";

export default class ModalAlert {
  static error({ title = "Oops...", message }) {
    Swal.fire({
      icon: "error",
      title: title,
      text: message,
    });
  }
  static success({ title = "Oops...", message }) {
    Swal.fire({
      icon: "success",
      title: title,
      text: message,
    });
  }

  static confirm({ title, message, confirmButtonText, cancelButtonText }) {
    return Swal.fire({
      title: title,
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3a009f",
      cancelButtonColor: "##6c757d",
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    });
  }
}
