<template>
  <div
    class="card custom-card box-shadow data-load"
    :class="{ 'sk-loading': isLoading }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <sk-spinner v-if="isLoading"></sk-spinner>
    <div class="card-body">
      <slot name="body" :value="value" :title="title">
        <slot name="title" :value="value" :title="title">
          <h5 class="card-title h-50">{{ title }}</h5>
        </slot>

        <slot name="value" :value="value" :title="title">
          <p class="card-text" @click="valueClicked(value)">{{ value }}</p>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script>
import { SkSpinner } from "@/components";

export default {
  name: "CardInfoComponent",
  components: {
    SkSpinner,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    valueClicked(value) {
      this.$emit("value-clicked-event", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-card {
  height: 150px;
  min-width: 150px;
  border-right: none;
  border-bottom: none;
  border-left: 4px solid $color-primary;
  width: 200px;

  & > .card-body .card-title {
    color: $color-gray;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22.4px;
  }

  & .card-text {
    color: $color-primary;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 49px;
  }
}
</style>
