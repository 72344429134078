<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "FormattedDateComponent",
  props: {
    val: {
      required: true,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    withoutYear: {
      type: Boolean,
      default: false,
    },
    withoutDate: {
      type: Boolean,
      default: false,
    },
    onlyDay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("lang", ["dateFormat", "withoutYearFormat", "dayFormat"]),
    getTimeFormat() {
      return this.withTime ? " HH:mm" : "";
    },
    getDateFormat() {
      if (this.onlyDay) return this.dayFormat;
      if (this.withoutDate) return "";
      if (this.withoutYear) return this.withoutYearFormat;
      else return this.dateFormat;
    },
    formattedValue() {
      return this.$isset(this.val)
        ? format(
            this.val instanceof Date ? this.val : this.$parseDate(this.val),
            `${this.getDateFormat}${this.getTimeFormat}`
          )
        : "";
    },
  },
};
</script>

<style scoped></style>
