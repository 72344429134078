export default class Options {
  /**
   *
   * @param {string} name
   * @param {string} id
   */
  add(name, id) {
    this[name] = id;
    return this;
  }
}
