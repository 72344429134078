<template>
  <div>
    <div style="width: 100%">
      <pdf
        v-if="src.length > 0"
        :src="source"
        @progress="loadedRatio = $event"
        @error="error"
        @num-pages="numPages = $event"
        @link-clicked="page = $event"
      ></pdf>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";

export default {
  components: {
    pdf,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    source() {
      return `data:application/pdf;base64,${this.src}`;
    },
  },
  data() {
    return {
      show: true,
      loadedRatio: 0,
      page: 1,
      numPages: 0,
      rotate: 0,
    };
  },
  methods: {
    error: function (err) {
      console.error(err);
    },
  },
};
</script>
