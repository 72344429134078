import LocalRepository from "../repository/local-repository";
import * as types from "@/store/mutation-types";
import { isset } from "@/utils/functions";
import { differenceInSeconds } from "date-fns";

import api from "@/api/plan";

const widgetCurrentDayData = "widget-current-day-data";

// state
export const state = {
  widgetCurrentDayData: LocalRepository.getData(widgetCurrentDayData, true),
  _widgetCurrentDayDataUpdatedAt: null,
  initializing: false,
};

// getters
export const getters = {
  widgetCurrentDayData: (state) => {
    return isset(state.widgetCurrentDayData) ? state.widgetCurrentDayData : [];
  },
  initializing: (state) => state.initializing,
};

// mutations
export const mutations = {
  [types.SET_WIDGET_PLANNING_DATA](state, data) {
    LocalRepository.setData(widgetCurrentDayData, data, true);
    state.widgetCurrentDayData = data;
  },
  [types.SET_WIDGET_PLANNING_DATA_UPDATED_AT](state, date) {
    state._widgetCurrentDayDataUpdatedAt = date;
  },
  [types.SET_WIDGET_PLANNING_DATA_INITIALIZING](state, status) {
    state.initializing = status;
  },
};

// actions
export const actions = {
  async getPlanEmployeeTypeAll({ commit, state }, date) {
    let currentDate = new Date();
    let firstInit = false;
    if (!isset(state._widgetCurrentDayDataUpdatedAt)) {
      commit(types.SET_WIDGET_PLANNING_DATA_UPDATED_AT, currentDate);
      firstInit = true;
    }
    //wait 20 sec to send new request
    if (firstInit || differenceInSeconds(currentDate, state._widgetCurrentDayDataUpdatedAt) > 20) {
      commit(types.SET_WIDGET_PLANNING_DATA_INITIALIZING, true);

      commit(types.SET_WIDGET_PLANNING_DATA_UPDATED_AT, currentDate);

      const { data } = await api.getPlanEmployeeTypeAll({
        from: date,
        to: date,
      });

      //   [
      //     {
      //       date: "2020-11-16T00:00:00"
      //       dayType: 6
      //     }
      //   ]

      commit(types.SET_WIDGET_PLANNING_DATA, data);
      commit(types.SET_WIDGET_PLANNING_DATA_INITIALIZING, false);
    }
  },
};
