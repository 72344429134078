import { isset, parseISOElseNull } from "@/utils/functions";

import {
  VACATIONS_TYPE_FILTER_APPROVED,
  VACATIONS_TYPE_FILTER_CONFIRMWAITING,
  VACATIONS_TYPE_FILTER_NEED_REUNION,
  VACATIONS_TYPE_FILTER_REJECTED,
  ABSENCE_TYPE_FILTER_SICK,
  ABSENCE_TYPE_FILTER_JUSTIFIED,
  ABSENCE_TYPE_FILTER_UNJUSTIFIED,
  ABSENCE_TYPE_FILTER_REJECTED,
  ABSENCE_TYPE_FILTER_CONFIRMWAITING,
} from "@/pages/components/";

import { STATUS_APPOINTMENT_CONFIRMWAITING } from "@/pages/components/constants";
import { Attachment } from "./index";

export default class Appointment {
  parse(data) {
    this.comments = data.comments;
    this.dateEnd = parseISOElseNull(data.dateEnd);
    this.dateStart = parseISOElseNull(data.dateStart);
    this.description = data.description;
    this.employeeCode = data.employeeCode;
    this.employeeName = data.employeeName;
    this.enjoyedVacations = data.enjoyedVacations;
    this.id = data.id;
    this.key = data.key;
    this.statusAppointment = data.statusAppointment;
    this.statusAppointmentDescription = data.statusAppointmentDescription;
    this.typeAppointment = data.typeAppointment;
    this.vacations = data.vacations;
    this.typeFilter = data.typeFilter;

    if (isset(data.attachments)) {
      this.attachments = data.attachments.map((x) => new Attachment().parse(x));
    }

    return this;
  }

  isStatusWaitingConfirm() {
    return this.statusAppointment == STATUS_APPOINTMENT_CONFIRMWAITING;
  }

  isVacationsRejected() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_REJECTED;
  }

  isVacationsNeedMeeting() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_NEED_REUNION;
  }

  isVacationsConfirmWaiting() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_CONFIRMWAITING;
  }

  isVacationsApproved() {
    return this.typeFilter == VACATIONS_TYPE_FILTER_APPROVED;
  }

  isTypeFilterSick() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_SICK;
  }

  isTypeFilterJustified() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_JUSTIFIED;
  }

  isTypeFilterUnjustified() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_UNJUSTIFIED;
  }

  isTypeFilterRejected() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_REJECTED;
  }

  isTypeFilterConfirmWaiting() {
    return this.typeFilter == ABSENCE_TYPE_FILTER_CONFIRMWAITING;
  }
}
