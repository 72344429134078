<template>
  <div class="row">
    <div class="col text-center">
      <img src="@/assets/IC_Msg_Information.svg" alt="icon" v-if="isInfo" />
      <img src="@/assets/IC_Msg_Error.svg" alt="icon" v-if="isError" />
      <img src="@/assets/IC_Msg_Alert.svg" alt="icon" v-if="isWarning" />
      <img src="@/assets/IC_Msg_Sucess.svg" alt="icon" v-if="isSuccess" />
      <div class="info mt-2 d-flex justify-content-center">
        <p class="w-50">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ERROR_TYPE_INFO_COMPONENT,
  INFO_TYPE_INFO_COMPONENT,
  WARNING_TYPE_INFO_COMPONENT,
  SUCCESS_TYPE_INFO_COMPONENT,
} from "./index";

export default {
  props: {
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: INFO_TYPE_INFO_COMPONENT,
      validator: function (val) {
        let types = [
          ERROR_TYPE_INFO_COMPONENT,
          INFO_TYPE_INFO_COMPONENT,
          WARNING_TYPE_INFO_COMPONENT,
          SUCCESS_TYPE_INFO_COMPONENT,
        ];
        if (!types.includes(val)) {
          console.error("InfoComponent should be one of the types", types);
          return false;
        }
        return true;
      },
    },
  },
  computed: {
    isInfo() {
      return this.type === INFO_TYPE_INFO_COMPONENT;
    },
    isError() {
      return this.type === ERROR_TYPE_INFO_COMPONENT;
    },
    isSuccess() {
      return this.type === SUCCESS_TYPE_INFO_COMPONENT;
    },
    isWarning() {
      return this.type === WARNING_TYPE_INFO_COMPONENT;
    },
  },
};
</script>

<style scoped>
.info {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
</style>
