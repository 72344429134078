export default class LocalRepository {
  static setData(key, data, serialize = false) {
    let sData = data;
    if (serialize) {
      sData = JSON.stringify(data);
    }
    localStorage.setItem(key, sData);
  }

  static getData(key, unserialize = false) {
    if (localStorage.getItem(key)) {
      const data = localStorage.getItem(key);
      return unserialize ? JSON.parse(data) : data;
    }
    return null;
  }

  static removeData(key) {
    localStorage.removeItem(key);
  }
}
