import axios from "axios";
import { buildUrl } from "@/config";
import { formatDate, isset } from "@/utils/functions";

export default {
  createAppointment(data) {
    return axios.post(buildUrl("api/v1/appointment"), data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  editAppointment(id, data) {
    return axios.put(buildUrl(`api/v1/appointment/${id}`), data);
  },

  revisionAppointment({ revision }) {
    return axios.put(buildUrl(`api/v1/appointment/revision-appointment`), revision);
  },

  removeAppointment(id) {
    return axios.post(buildUrl(`api/v1/appointment/remove/${id}`));
  },

  getMyVacations({ start, end }) {
    const params = new URLSearchParams([
      ["dateStart", formatDate(start)],
      ["dateEnd", formatDate(end)],
    ]);
    return axios.get(buildUrl(`api/v1/appointment/my-vacations/`), { params });
  },

  getMyAbsences({ start, end }) {
    const params = new URLSearchParams([
      ["dateStart", formatDate(start)],
      ["dateEnd", formatDate(end)],
    ]);
    return axios.get(buildUrl(`api/v1/appointment/my-absences/`), { params });
  },

  getTypeAbsenses() {
    return axios.get(buildUrl(`api/v1/absence`));
  },

  getFilteredAbsences({ employeeCode, status }) {
    const params = new URLSearchParams();
    if (isset(employeeCode)) {
      params.append("code", employeeCode);
    }
    if (isset(status)) {
      params.append("status", status);
    }
    return axios.get(buildUrl(`api/v1/appointment/filtered`), { params });
  },

  getMyFilteredAbsences({ status }) {
    const params = new URLSearchParams();
    if (isset(status)) {
      params.append("status", status);
    }
    return axios.get(buildUrl(`api/v1/appointment/my-filtered`), { params });
  },

  getMyUnreadAppointments() {
    return axios.get(buildUrl(`api/v1/appointment/my-unread-appointment`));
  },

  getDetailAbsence({ employeeCode, id, source }) {
    const params = new URLSearchParams([
      ["EmployeeCode", employeeCode],
      ["Id", id],
      ["Source", source],
    ]);
    return axios.get(buildUrl("api/v1/appointment/detail-absence"), { params });
  },

  downloadAttachment({ employeeCode, reference }) {
    const params = new URLSearchParams([
      ["EmployeeCode", employeeCode],
      ["Reference", reference],
    ]);

    return axios.get(buildUrl(`api/v1/appointment/download-attachment`), {
      responseType: "arraybuffer",
      params,
    });
  },

  updateAppointmentRead({ id, employeeRead }) {
    return axios.put(buildUrl(`api/v1/appointment/read`), { id, employeeRead });
  },
};
