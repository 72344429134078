<template>
  <vue-modal
    :show-modal="showModal"
    :showSpinner="isLoading"
    :loadingPrimaryFooterButton="isLoading"
    :primary-footer-button-translation="$t('components.actions.confirm')"
    :centered="true"
    :showPrimaryFooterButton="true"
    :showSecondaryFooterButton="false"
    @[closeEvent]="onCloseButtonClicked"
    @[primaryEvent]="onPrimaryButtonClicked"
    @[secondaryEvent]="onCloseButtonClicked"
  >
    <template #body>
      <info-component :message="message" :type="modalType"></info-component>
    </template>
  </vue-modal>
</template>

<script>
import VueModal, {
  CLOSE_BUTTON_CLICKED_MODAL_EVENT,
  PRIMARY_BUTTON_CLICKED_MODAL_EVENT,
  SECONDARY_BUTTON_CLICKED_MODAL_EVENT,
} from "@/components/modal";

import InfoComponent, { WARNING_TYPE_INFO_COMPONENT } from "@/pages/components/info";

import { SHOW_CONFIRM_INFO_MODAL } from "./index";

export default {
  components: {
    VueModal,
    InfoComponent,
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      action: null,
      message: "",
    };
  },
  created() {
    EventBus.listen(SHOW_CONFIRM_INFO_MODAL, ({ message, action }) => {
      this.message = message;
      this.action = action;
      this.showModal = true;
    });
  },
  computed: {
    primaryEvent() {
      return PRIMARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    secondaryEvent() {
      return SECONDARY_BUTTON_CLICKED_MODAL_EVENT;
    },
    closeEvent() {
      return CLOSE_BUTTON_CLICKED_MODAL_EVENT;
    },
    modalType() {
      return WARNING_TYPE_INFO_COMPONENT;
    },
  },
  methods: {
    onCloseButtonClicked() {
      this.showModal = false;
    },
    async onPrimaryButtonClicked() {
      this.isLoading = true;
      await this.action();
      this.isLoading = false;
      this.showModal = false;
    },
  },
};
</script>

<style scoped></style>
